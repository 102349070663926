import React, { useEffect, useState } from "react";
import LanguageSelector from "../language-selector";
import { getAssetPath } from "../services/helpers";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

function Header() {
  const [color, setColor] = useState(false);
  const location = useLocation();
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  const [scrollPosition, getScrollPositon] = useState(
    document.documentElement.scrollTop
  );
  useEffect(() => {
    window.addEventListener("scroll", () => {
      getScrollPositon(document.documentElement.scrollTop);
    });
  }, []);

  window.addEventListener("scroll", changeColor);

  useEffect(() => {
    if (location.pathname.includes("TenderApply")) {
      setColor(true);
    } else {
      setColor(false);
    }
  }, [location.pathname]);

  const { t } = useTranslation();

  const {
    home,
    about,
    departments,
    products,
    awards,
    farmerCorner,
    krantiGallery,
    ContactUs,
  } = t("header");

  return (
    <header className={color ? "scroll-header-bg" : "header"}>
      <div className="lang-mobswitch mobile-switch-display-none">
        <LanguageSelector />
      </div>
      <nav className="navbar fixed-top navbar-expand-lg bg-body-tertiary main-navbar">
        <div className="container">
          <a className="navbar-brand mobile-logo-pd" href={getAssetPath('')}>
            <img
              className="kranti-logo"
              alt="comp-logo"
              src={
                scrollPosition < 90
                  ? getAssetPath("assets/imgs/kranti-header-white.png")
                  : getAssetPath("assets/imgs/kranti-new-black.png")
              }
            ></img>
            <img className="mobile-kranti-logo" alt="kranti-logo" src={getAssetPath("assets/imgs/kranti-new-black.png")}></img>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse cstm-collapse"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
              <a className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}
                aria-current="page"
                href="/">
                {home.title}
              </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className={`nav-link dropdown-toggle ${['/AboutUs', '/OurTeam','/CaneDevelopment','/OtherFacility','/NewsRoom'].includes(location.pathname) ? 'active' : ''}`}
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  href="/"
                >
                  {about.title}
                </a>
                <ul
                  className="dropdown-menu animate slideIn"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li className="inner-link">
                    <a className={`dropdown-item ${location.pathname === "/AboutUs" ? 'active' : ''}`} href={getAssetPath("AboutUs")}>
                      {about.dropDown1}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a className={`dropdown-item ${location.pathname === "/OurTeam" ? 'active' : ''}`} href={getAssetPath("OurTeam")}>
                      {about.dropDown2}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/CaneDevelopment" ? 'active' : ''}`}
                      href={getAssetPath("CaneDevelopment")}
                    >
                      {about.dropDown3}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/OtherFacility" ? 'active' : ''}`}
                      href={getAssetPath("OtherFacility")}
                    >
                      {about.dropDown4}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/NewsRoom" ? 'active' : ''}`}
                      href={getAssetPath("NewsRoom")}
                    >
                      {about.dropDown5}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className={`nav-link dropdown-toggle ${['/Administration', '/Accounts','/Agriculture','/Manufacturing','/Labortime','/DeptCaneDevelopment','/Computer','/CoGeneration','/Distillery','/ESL','/Civil','/Engineering'].includes(location.pathname) ? 'active' : ''}`}
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  href="/"
                >
                  {departments.title}
                </a>
                <ul
                  className="dropdown-menu animate slideIn"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/Administration" ? 'active' : ''}`}
                      href={getAssetPath("Administration")}
                    >
                      {departments.dropDown1}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/Accounts" ? 'active' : ''}`}
                      href={getAssetPath("Accounts")}
                    >
                      {departments.dropDown2}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/Agriculture" ? 'active' : ''}`}
                      href={getAssetPath("Agriculture")}
                    >
                      {departments.dropDown3}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/Manufacturing" ? 'active' : ''}`}
                      href={getAssetPath("Manufacturing")}
                    >
                      {departments.dropDown4}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/Labortime" ? 'active' : ''}`}
                      href={getAssetPath("Labortime")}
                    >
                      {departments.dropDown5}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/DeptCaneDevelopment" ? 'active' : ''}`}
                      href={getAssetPath("DeptCaneDevelopment")}
                    >
                      {departments.dropDown6}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/Computer" ? 'active' : ''}`}
                      href={getAssetPath("Computer")}
                    >
                      {departments.dropDown7}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/CoGeneration" ? 'active' : ''}`}
                      href={getAssetPath("CoGeneration")}
                    >
                      {departments.dropDown8}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === "/Distillery" ? 'active' : ''}`}
                      href={getAssetPath("Distillery")}
                    >
                      {departments.dropDown9}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a className={`dropdown-item ${location.pathname === "/ESL" ? 'active' : ''}`} href={getAssetPath("ESL")}>
                      {departments.dropDown10}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a className={`dropdown-item ${location.pathname === "/Civil" ? 'active' : ''}`} href={getAssetPath("Civil")}>
                      {departments.dropDown11}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a className={`dropdown-item ${location.pathname === "/Engineering" ? 'active' : ''}`} href={getAssetPath("Engineering")}>
                      {departments.dropDown12}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className={`nav-link dropdown-toggle ${['/MajorProducts', '/BioProducts'].includes(location.pathname) ? 'active' : ''}`}
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  href="/"
                >
                  {products.title}
                </a>
                <ul
                  className="dropdown-menu animate slideIn"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === getAssetPath("MajorProducts") ? 'active' : ''}`}
                      href={getAssetPath("MajorProducts")}
                    >
                      {products.dropDown1}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                  <li className="inner-link">
                    <a
                      className={`dropdown-item ${location.pathname === getAssetPath("BioProducts") ? 'active' : ''}`}
                      href={getAssetPath("BioProducts")}
                    >
                      {products.dropDown2}
                      <img
                      className="arrow-1"
                      alt="arrow"
                      src={getAssetPath("assets/icons/Arrow-1.png")}
                    ></img>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${location.pathname === '/Awards' ? 'active' : ''}`} href={getAssetPath("Awards")}>
                  {awards.title}{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${location.pathname === '/FarmerInfo' ? 'active' : ''}`} href={getAssetPath("FarmerInfo")}>
                  {farmerCorner.title}
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${location.pathname === '/KrantiGallery' ? 'active' : ''}`} href={getAssetPath("KrantiGallery")}>
                  {krantiGallery.title}
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${location.pathname === '/ContactUs' ? 'active' : ''}`} href={getAssetPath("ContactUs")}>
                  {ContactUs.title}
                </a>
              </li>
            </ul>
          </div>
          <div className="lang-switch desk-view">
            <LanguageSelector />
          </div>
        </div>
      </nav>
    </header>
  );
}
export default Header;
