import React, {useEffect, useState} from "react";
import "./NewsRoom.css";
import { getAssetPath } from "../../services/helpers";
import { useTranslation } from "react-i18next";
import { imageNames } from "./staticImageArray";
import {useGetHomePageDetailQuery, useGetNewsRoomDetailQuery} from "../../services/userAuthApi";
import i18next from "i18next";

const NewsRoom = () => {

    const { t } = useTranslation();

    const { title, link1, text1, text2  } = t("newsRoom")

    const [selectedImage, setSelectedImage] = useState(null);
    const [newsImageList, setNewsImageList] = useState([]);  //notice board
    const { data: newsPageResponse , isSuccess} = useGetNewsRoomDetailQuery();

    useEffect(() => {
        if (newsPageResponse && isSuccess) {
            const response = newsPageResponse.data;
            setNewsImageList(response);
        }
    }, [newsPageResponse, isSuccess]);

    const openModal = (imageName) => {
        setSelectedImage(imageName);
    }

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="newsroom-banner col-md-12">
          <div className="container">
                      <h1 className="headingtenders font-bold">{title}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                                      <a href="/home">{link1}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                                      {text1}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="simple-idea">
        <div className="container">
          <div className="row ">
            <div className="container col-md-5">
              <h2 className="idea-title">{text2}</h2>
            </div>
          </div>
          <div className="row mt-5">
            {newsImageList.map((item, index) => (
                <div className="col-md-4">
                    <div className="deve-facility">
                        <div className="cane-facility-img" data-bs-toggle="modal" data-bs-target="#Modalarticles" key={item.id}  onClick={() => openModal(item.file_path)}>
                            <img className="img-fluid gallery-img" src={item.file_path} alt={item.title} />
                        </div>
                    </div>
                </div>
            ))}
          </div>
        </div>
      </section>

      <div className="modal fade" id="Modalarticles" tabIndex="-1" aria-labelledby="Modalarticles" aria-hidden="true">
          <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content">
                  <div className="modal-body">
                      <a href="/" className="close" data-bs-dismiss="modal">
                          <img className="img-fluid" alt="close-modal" src={getAssetPath("assets/icons/close-modal.png")}></img>
                      </a>
                      <div className="row">
                          <div className="col-md-12">
                              <div className="leadership-modal-img">
                                  <img className="img-fluid ks-gallery-img w-100" alt="gallery-img" src={selectedImage}></img>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
};

export default NewsRoom;
