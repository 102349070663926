import React, {useEffect, useState} from "react";
import "./FcCaneBill.css";
import {getAssetPath} from "../../../services/helpers";
import {useTranslation} from "react-i18next";
import {getApiDiffHeaders} from "../../../services/api/api";
import {getLocalStorageData, storeData} from "../../../services/localStorageService";
import {useNavigate} from "react-router-dom";
import moment from 'moment';
import DetailModal from "./DetailModal";
const FcCaneBill = () => {
    const {t} = useTranslation();
    const {bannerInfo, registerTable} = t("fccaneweight");
    const navigate = useNavigate();
    const [storedUserData, setStoredUserData] = useState(null);
    const [storedYearMaster, setStoredYearMaster] = useState(null);
    const [farmerCode, setFarmerCode] = useState(null);
    const [seasonSelected, setSeasonSelected] = useState(null);
    const [billList, setBillList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBill, setSelectedBill] = useState(null);

    useEffect(() => {
        if (setSeasonSelected !== null && storedUserData != null) {
            fetchAndSetCaneBill().catch((error) => console.error("Error in fetchAndSetCaneBill:", error));
        }
    }, [seasonSelected]);

    useEffect(() => {
        const fetchLocalStorageData = async() => {
            try {
                const userData = JSON.parse(getLocalStorageData('userDetails')) ?? null;
                const yearMaster = JSON.parse(getLocalStorageData('seasonYears')) ?? [];
                const localStorageDate = getLocalStorageData('seasonDate') ?? null;
                const fCode = getLocalStorageData('farmerCode') ?? null;

                setFarmerCode(fCode);
                setStoredUserData(userData);
                setStoredYearMaster(yearMaster);

                if (localStorageDate) {
                    setSeasonSelected(localStorageDate);
                } else if (Array.isArray(yearMaster) && yearMaster.length > 0) {
                    setSeasonSelected(yearMaster[0]?.desc_en);
                }
            } catch (error) {
                console.error('Failed to fetch local storage data:', error);
            }
        };

        fetchLocalStorageData();
    }, []);

    const handleToRedirectLocation = (path, param = null) => {
        navigate(path, param ? { state: { param, 'seasonDate':seasonSelected } } : {});
    };
    const handleSeasonDateSelection = (season) => {
        setSeasonSelected(season);
        storeData('seasonDate', season);
    };

    const fetchAndSetCaneBill = async () => {
        const actualData = {
            Registration_Season: seasonSelected,
            Farmer_Code: farmerCode,
        };

        try {
            const caneBillResponse = await getApiDiffHeaders('canebill/', actualData);

            // Check if response is valid and contains expected data
            if (caneBillResponse && caneBillResponse.result) {
                const billArray = Object.values(caneBillResponse.result);
                setBillList(billArray);
                // setCaneBill(caneBillResponse.total?.Total_Tonnage ?? null); // Optional chaining for safety
            } else {
                // Handle case where response structure is not as expected
                setBillList([]);
                console.warn('Unexpected response structure:', caneBillResponse);
            }
        } catch (error) {
            console.error('Failed to fetch cane bill data:', error);
            // Optionally, set an error state to display to the user
        }
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat('en-US').format(amount);
    };

    const openDynamicModel = (bill) => {
        setSelectedBill(bill);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedBill(null);
    };

    return (
        <>
            <div className="Tenders-headbanner">
                <div className="Farmer-banner col-md-12">
                    <div className="container">
                        <h1 className="headingtenders font-bold">Sugarcane Bills</h1>
                        <div className="breadcrum">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb bg-container">
                                    <li className="breadcrumb-item brdm-itm">
                                        <button
                                            onClick={() => handleToRedirectLocation('/home')}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: 'inherit', // Use inherited color from parent
                                                textDecoration: 'underline', // Makes it look like a link
                                                cursor: 'pointer',
                                            }}
                                            tabIndex={0} // Make it focusable for keyboard users
                                        >
                                            {bannerInfo.link}
                                        </button>
                                    </li>
                                    <li
                                        className="breadcrumb-item brdm-itm"
                                        aria-current="page">
                                        <button
                                            onClick={() => handleToRedirectLocation('/fc-dashboard')}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: 'inherit', // Use inherited color from parent
                                                textDecoration: 'underline', // Makes it look like a link
                                                cursor: 'pointer',
                                            }}
                                            tabIndex={0} // Make it focusable for keyboard users
                                        >
                                            {bannerInfo.link}
                                        </button>
                                    </li>
                                    <li
                                        className="breadcrumb-item brdm-itm active"
                                        aria-current="page">
                                        Farmer Cane Bill
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <section className="tender-table">
                <div className="tendermain-table">
                    <div className="col-md-12">
                        <div className="famrer-logout mb-3">
                            <button
                                onClick={() => {
                                    handleToRedirectLocation('/fc-dashboard')
                                }}
                                className="btn btn-default btn-round tender-Register btn-back"
                                >
                                    <img
                                        className="back"
                                        alt="back"
                                        src={getAssetPath("assets/icons/back-arrow.png")}
                                    ></img>
                                    {registerTable.back}
                                </button>
                        </div>
                        <div className="login-search mb-30">
                            <div className="farmer-name">
                                <div className="username">
                                   <h2 className="font-bold">
                                       Sugarcane Bill
                                   </h2>
                                </div>
                            </div>
                            <div className="season-selectn">
                                <p className="selectreg-title font-regular">
                                    {registerTable.selectTitle}
                                </p>

                                <div className="dropdown">
                                    <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Season {seasonSelected}
                                    </button>
                                    <ul
                                        className="dropdown-menu drpdown-height"
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        {Array.isArray(storedYearMaster) && storedYearMaster.map((season) => (
                                            <li key={season.season_yr_id}>
                                                <button
                                                    className="dropdown-item"
                                                    onClick={() => handleSeasonDateSelection(season.desc_en)}
                                                >
                                                    Season {season.desc_en}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive mb-3">
                        <table className="table table-striped table-hover ">
                                <thead className="thead-dark th-height">
                                <tr>
                                    <th scope="col">Bill Date</th>
                                    <th scope="col">Bill Period From</th>
                                    <th scope="col" >Bill Period To</th>
                                    <th scope="col">Bill Type</th>
                                    <th scope="col">Bill ID</th>
                                    <th scope="col">Bill Amount</th>
                                    <th scope="col">Payable Amount</th>
                                    <th scope="col">Details</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Array.isArray(billList) && billList.length > 0 ? (
                                    billList.map((singleReg,index) => (
                                        <tr className="tr-height"
                                            key={billList.length - index}> {/* Make sure to use a unique key */}
                                            <td>{moment(singleReg.Cane_Bill_Date).format('MMMM Do YYYY')  || 'N/A'}</td>
                                            <td>{moment(singleReg.Cane_Bill_Period_From).format('MMMM Do YYYY')  || 'N/A'}</td>
                                            <td>{moment(singleReg.Cane_Bill_Period_To).format('MMMM Do YYYY')  || 'N/A'}</td>
                                            <td>{singleReg.Cane_Bill_Type || 'N/A'}</td>
                                            <td >{singleReg.Cane_Bill_ID || 'N/A'}</td>
                                            <td>{formatAmount(singleReg.Bill_Amount) || 'N/A'}</td>
                                            <td>{formatAmount(singleReg.Payable_Amount)  || 'N/A'}</td>
                                            <td>
                                                <button
                                                    onClick={() => {
                                                        openDynamicModel(singleReg);
                                                    }}
                                                    className="btn btn-default btn-round Download">
                                                    {registerTable.buttons.viewdetail}
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8">
                                            <p className="result-text text-center regular-font">No Farmer Cane Bill Found</p>
                                        </td>
                                    </tr>

                                )}
                                </tbody>
                            </table>
                        </div>
                        {
                            Array.isArray(billList) && billList.length > 0 ?
                                <p className="result-text text-center regular-font">{registerTable.results.text1} {billList.length} {registerTable.results.text2}</p>
                                :null
                        }
                        <DetailModal
                            isOpen={isModalOpen}
                            onClose={closeModal}
                            data={selectedBill}
                        />

                    </div>
                </div>
            </section>
        </>
    )
}

export default FcCaneBill
