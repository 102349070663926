import React from "react";
import "./Agriculture.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../../services/helpers";

import { useTranslation } from "react-i18next";

const Agriculture = () => {
  const { t } = useTranslation();

  const { banner, newsRoom, extrapara, tenderMain_Table } = t("Agriculture");

  var AgricultureImgs = {
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    fade: true,
    cssEase: "linear",
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

  }

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="agriculture-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.title}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="ngfc-bg">
        <div className="container">
          <div className="newsroom">
            <div className="row mb-4 d-flex justify-content-center">
              <div className="col-md-6">
                <h2 className="investor-title mb-4">{newsRoom.heading}</h2>
                <p className="department-sbtxt ">{newsRoom.para}</p>
                <br />
                <p className="department-sbtxt ">{newsRoom.para1}</p>
              </div>
              <div className="col-md-6">
                <div className="agriculture-multimg">
                  <Slider {...AgricultureImgs}>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="agriculture"
                        src={getAssetPath("assets/imgs/department/agriculture/agri-img1.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="agriculture"
                        src={getAssetPath("assets/imgs/department/agriculture/agri-img2.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="agriculture"
                        src={getAssetPath("assets/imgs/department/agriculture/agri-img3.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="agriculture"
                        src={getAssetPath("assets/imgs/department/agriculture/agri-img4.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="agriculture"
                        src={getAssetPath("assets/imgs/department/agriculture/agri-img5.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="agriculture"
                        src={getAssetPath("assets/imgs/department/agriculture/agri-img6.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="agriculture"
                        src={getAssetPath("assets/imgs/department/agriculture/agri-img7.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="agriculture"
                        src={getAssetPath("assets/imgs/department/agriculture/agri-img8.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="agriculture"
                        src={getAssetPath("assets/imgs/department/agriculture/agri-img9.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="agriculture"
                        src={getAssetPath("assets/imgs/department/agriculture/agri-img10.jpg")}
                      ></img>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tender-table">
        <div className="container">
          <div className="tendermain-table">
            <div className="col-md-12">
              <div className="login-search">
                <div className="farmer-name">
                  <div className="username investor-title">
                    <h2 className="font-bold" style={{fontWeight : '600'}}>{extrapara.heading}</h2>
                  </div>
                </div>
              </div>

            <div class="accordion" id="accordionPanelsStayOpenExample">
              <div class="accordion-item custom-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                  <button class="accordion-button custom-accordoin-btn" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                    <h4 class="facility-name font-bold">{extrapara.table1.heading1}</h4>
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                  <div class="accordion-body">
                    <div className="row"> 
                      <div className="col-md-4">
                        <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img1.jpg")}></img>
                        </div>
                      <div className="col-md-8">
                        <div className="bt-text font-regular ">
                        <p>{extrapara.table1.para1}</p>
                        <br/>
                        <p>{extrapara.table1.paratwo}</p>
                        <br/>
                        <p>{extrapara.table1.parathree}</p>
                        <br/>
                        <p>{extrapara.table1.parafour}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      <h4 class="facility-name font-bold">{extrapara.table2.heading2}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                    <div class="accordion-body">
                      <div className="row"> 
                        <div className="col-md-4">
                          <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img2.jpg")}></img>
                        </div>
                        <div className="col-md-8">
                          <div className="bt-text font-regular ">
                            <p>{extrapara.table2.para2}</p>
                            <br/>
                            <p>{extrapara.table2.paratwo}</p>
                            <br/>
                            <p>{extrapara.table2.parathree}</p>
                            <br/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table3.heading3}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="row"> 
                          <div className="col-md-4">
                            <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img3.jpg")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text font-regular ">
                              <p>{extrapara.table3.para3}</p>
                              <br />
                              <p>{extrapara.table3.paratwo}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingfour">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table4.heading4}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapsefour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="row"> 
                          <div className="col-md-4">
                            <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img4.jpg")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text font-regular ">
                              <p>{extrapara.table4.para4}</p>
                              <br />
                              <p>{extrapara.table4.paratwo}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingfive">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefive" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table5.heading5}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapsefive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="row"> 
                          <div className="col-md-4">
                            <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img5.jpg")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text font-regular ">
                              <p>{extrapara.table5.para5}</p>
                              <br />
                              <p>{extrapara.table5.paratwo}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingsix">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsesix" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table6.heading6}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapsesix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="row"> 
                          <div className="col-md-4">
                            <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img6.jpg")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text font-regular ">
                              <p>{extrapara.table6.para6}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingseven">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseseven" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table7.heading7}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseseven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="row"> 
                          <div className="col-md-4">
                            <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img7.jpg")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text font-regular ">
                              <p>{extrapara.table7.para7}</p>
                              <br />
                              <p>{extrapara.table7.paratwo}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingeght">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseseght" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table8.heading8}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseseght" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="row"> 
                          <div className="col-md-4">
                          <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agricutlure-img-8new.jpeg")}
                            style={{objectFit: "contain", height: "unset!important"}}
                            ></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text font-regular ">
                              <p>{extrapara.table8.para8}</p>
                              <br/>
                              <p>{extrapara.table8.paratwo}</p>
                              <br/>
                              <p>{extrapara.table8.parathree}</p>
                              <p>{extrapara.table8.parafour}</p>
                              <br/>
                              <p>{extrapara.table8.parafive}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingenine">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsesnine" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table9.heading9}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapsesnine" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="row"> 
                          <div className="col-md-4">
                            <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img9.png")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text font-regular ">
                              <p>{extrapara.table9.para9}</p>
                              <br />
                              <p>{extrapara.table9.paratwo}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingeten">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsesten" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table10.heading10}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapsesten" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="row"> 
                          <div className="col-md-4">
                            <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img10.jpg")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text font-regular ">
                              <p>{extrapara.table10.para10}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingeeleven">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseseleven" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table11.heading11}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseseleven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="row"> 
                          <div className="col-md-4">
                            <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img11.jpeg")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text font-regular ">
                              <p>{extrapara.table11.para11}</p>
                              <br />
                              <p>{extrapara.table11.paratwo}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingetweleve">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsestweleve" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table12.heading12}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapsestweleve" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="row"> 
                          <div className="col-md-4">
                            <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img12.jpg")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text font-regular ">
                              <p>{extrapara.table12.para12}</p>
                              <br />
                              <p>{extrapara.table12.paratwo}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge13">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses13" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table13.heading13}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses13" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="row"> 
                          <div className="col-md-4">
                            <img className="collapse-imgs" alt="agriculture" src={getAssetPath("assets/imgs/department/agriculture/agriculture-img13.jpg")}></img>
                          </div>
                          <div className="col-md-8">
                            <div className="bt-text font-regular ">
                              <p>{extrapara.table13.para13}</p>
                              <br />
                              <p>{extrapara.table13.paratwo}</p>
                              <br />
                              <p>{extrapara.table13.parathree}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              {/* <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge14">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses14" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table14.heading14}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses14" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{extrapara.table14.para14}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge15">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses15" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table15.heading15}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses15" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{extrapara.table15.para15}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge16">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses16" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table16.heading16}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses16" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{extrapara.table16.para16}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge17">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses17" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table17.heading17}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses17" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{extrapara.table16.para16}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge18">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses18" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table18.heading18}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses18" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{extrapara.table18.para18}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge19">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses19" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table19.heading19}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses19" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{extrapara.table19.para19}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge20">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses20" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table20.heading20}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses20" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{extrapara.table20.para20}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge21">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses21" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table21.heading21}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses21" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{extrapara.table21.para21}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge22">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses22" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table22.heading22}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses22" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{extrapara.table22.para22}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge23">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses23" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table23.heading23}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses23" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{extrapara.table23.para23}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="accordion-item custom-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headinge24">
                    <button class="accordion-button custom-accordoin-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapses24" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      <h4 class="facility-name font-bold">{extrapara.table24.heading24}</h4>
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapses24" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div className="bt-text font-regular ">
                        <p>{extrapara.table24.para24}</p>
                      </div>
                    </div>
                  </div>
              </div> */}
            </div>

            
            {/* <section>
            <div className="bt-title font-semibold">
              <p>{extrapara.table1.heading1}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table1.para1}</p>
            </div>
            
            <div className="bt-title font-semibold">
              <p>{extrapara.table2.heading2}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table2.para2}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table3.heading3}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table3.para3}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table4.heading4}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table4.para4}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table5.heading5}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table5.para5}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table6.heading6}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table6.para6}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table7.heading7}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table7.para7}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table8.heading8}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table8.para8}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table9.heading9}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table9.para9}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table10.heading10}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table10.para10}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table11.heading11}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table11.para11}</p>
            </div>
            


            <div className="bt-title font-semibold">
              <p>{extrapara.table12.heading12}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table12.para12}</p>
            </div>
            


            <div className="bt-title font-semibold">
              <p>{extrapara.table13.heading13}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table13.para13}</p>
            </div>
            


            <div className="bt-title font-semibold">
              <p>{extrapara.table14.heading14}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table14.para14}</p>
            </div>
            


            <div className="bt-title font-semibold">
              <p>{extrapara.table15.heading15}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table15.para15}</p>
            </div>
            


            <div className="bt-title font-semibold">
              <p>{extrapara.table16.heading16}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table16.para16}</p>
            </div>
            


            <div className="bt-title font-semibold">
              <p>{extrapara.table17.heading17}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table17.para17}</p>
            </div>
            


            <div className="bt-title font-semibold">
              <p>{extrapara.table18.heading18}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table18.para18}</p>
            </div>
            


            <div className="bt-title font-semibold">
              <p>{extrapara.table19.heading19}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table19.para19}</p>
            </div>
            


            <div className="bt-title font-semibold">
              <p>{extrapara.table20.heading20}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table20.para20}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table21.heading21}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table21.para21}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table22.heading22}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table22.para22}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table23.heading23}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table23.para23}</p>
            </div>
            

            <div className="bt-title font-semibold">
              <p>{extrapara.table24.heading24}</p>
            </div>
            <div className="bt-text font-regular mb-3">
              <p>{extrapara.table24.para24}</p>
            </div>
            ------------------------------------
            </section> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Agriculture;







