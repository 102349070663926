import React, {useEffect, useState} from "react";
import './KrantiGallery.css'
import {getAssetPath} from "../../services/helpers";
import {useTranslation} from "react-i18next";
import {useGetGalleryImagesDetailQuery} from "../../services/userAuthApi";

const KrantiGallery = () => {
    const { t } = useTranslation();
    const { heading, link1, link2, btn1, btn2 } = t("KrantiGallery");
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const [imageList, setImageList] = useState([]);
    const [videoList, setVideoList] = useState([]);

    const {data: galleryImagesResponse} = useGetGalleryImagesDetailQuery();

    useEffect(() => {
        if (galleryImagesResponse && galleryImagesResponse.code === 200) {
            // Filter images (type: 1) and videos (type: 2)
            const images = galleryImagesResponse.data.filter(item => item.type === 1);
            const videos = galleryImagesResponse.data.filter(item => item.type === 2);
            // Set the filtered data to the state
            setImageList(images);
            setVideoList(videos);
        }
    }, [galleryImagesResponse]);

    const openModal = (imageName) => {
        setSelectedImage(imageName);
    }

    const openVideoModal = (videoName) => {
        setSelectedVideo(videoName);
    }

    const getThumbnail = (videoFilePath) => {
        // Replace any video extension (e.g., .mp4, .avi, .mov, etc.) with '-thumb.jpg'
        return videoFilePath.replace(/\.[^/.]+$/, '.jpg');
    }

    return(
        <>
        <div className="Tenders-headbanner">
            <div className="gallery-banner col-md-12">
                <div className="container">
                    <h1 className="headingtenders font-bold">{heading}</h1>
                    <div className="breadcrum">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-container">
                            <li className="breadcrumb-item brdm-itm"><a href="/home">{link1}</a></li>
                            <li className="breadcrumb-item brdm-itm active" aria-current="page">{link2}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <section className="gallery-tabs">
            <div className="container">
                <ul className="nav nav-pills justify-content-center" id="pills-tab" role="tablist">
                    <div className="awards-bg-pill">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active custom-tab" id="Pg-tab" data-bs-toggle="tab" data-bs-target="#Pg" type="button" role="tab" aria-controls="Pg" aria-selected="true">{btn1}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link custom-tab" id="Vg-tab" data-bs-toggle="tab" data-bs-target="#Vg" type="button" role="tab" aria-controls="Vg" aria-selected="false">{btn2}</button>
                        </li>
                    </div>
                </ul>
            </div>
            <div className="tab-content custom-tb-cnt" id="myTabContent">
                <div className="tab-pane fade show active" id="Pg" role="tabpanel" aria-labelledby="Pg-tab">
                    <section className="tab-awards-bg">
                        <div className="container">
                            <div className="row">
                                {imageList.map((item, index) => (
                                    <div className="col-md-4">
                                        <div className="deve-facility">
                                            <div className="cane-facility-img" data-bs-toggle="modal" data-bs-target="#Modalimg" key={index}  onClick={() => openModal(item.file_path)}>
                                                <img className="img-fluid gallery-img" src={item.file_path} alt={item.title} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
                <div className="tab-pane fade show" id="Vg" role="tabpanel" aria-labelledby="Vg-tab">
                    <section className="tab-awards-bg">
                        <div className="container">
                            <div className="row">
                            {videoList.map((item, index) => (
                                <div className="col-md-4">
                                        <div className="deve-facility">
                                            <div className="cane-facility-img" data-bs-toggle="modal" data-bs-target="#Modalvideo" key={item.id}  onClick={() => openVideoModal(item.file_path)}>
                                                <img className="img-fluid gallery-img" src={getThumbnail(item.file_path)} alt={item.title} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                    </section>
                </div>
            </div>
        </section>
        <div className="modal fade" id="Modalimg" tabIndex="-1" aria-labelledby="Modalimg" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <a href="/" className="close" data-bs-dismiss="modal">
                            <img className="img-fluid" alt="close-modal" src={getAssetPath("assets/icons/close-modal.png")}></img>
                        </a>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadership-modal-img">
                                    <img className="img-fluid ks-gallery-img w-100" alt="gallery-img" src={selectedImage}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="Modalvideo" tabIndex="-1" aria-labelledby="Modalvideo" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <a href="/" className="close" data-bs-dismiss="modal">
                            <img className="img-fluid" alt="close-modal" src={getAssetPath("assets/icons/close-modal.png")}></img>
                        </a>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadership-modal-img">
                                    <video width="100%" height="500" controls autoPlay controlsList="nodownload" src={selectedVideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" className="" allowfullscreen>
                                    </video>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default KrantiGallery