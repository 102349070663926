import React, {useEffect, useState} from "react";
import "./FcCaneWeight.css";
import {getAssetPath} from "../../../services/helpers";
import {useTranslation} from "react-i18next";
import {getApiDiffHeaders} from "../../../services/api/api";
import {getLocalStorageData, storeData} from "../../../services/localStorageService";
import {useNavigate} from "react-router-dom";

const FcCaneWeight = () => {
    const {t} = useTranslation();
    const {bannerInfo, registerTable} = t("fccaneweight");
    const navigate = useNavigate();
    const [storedUserData, setStoredUserData] = useState(null);
    const [storedYearMaster, setStoredYearMaster] = useState(null);
    const [farmerCode, setFarmerCode] = useState(null);
    const [seasonSelected, setSeasonSelected] = useState(null);
    const [registrationList, setRegistrationList] = useState([]);
    const [totalTonnage, setTotalTonnage] = useState(0);

    useEffect(() => {
        if (setSeasonSelected !== null && storedUserData != null) {
            fetchAndSetTonnageList().catch((error) => console.error("Error in fetchTonnageList:", error));
        }
    }, [seasonSelected]);

    useEffect(() => {
        const fetchLocalStorageData = async() => {
            try {
                const userData = JSON.parse(getLocalStorageData('userDetails')) ?? null;
                const yearMaster = JSON.parse(getLocalStorageData('seasonYears')) ?? [];
                const localStorageDate = getLocalStorageData('seasonDate') ?? null;
                const fCode = getLocalStorageData('farmerCode') ?? null;

                setFarmerCode(fCode);
                setStoredUserData(userData);
                setStoredYearMaster(yearMaster);

                if (localStorageDate) {
                    setSeasonSelected(localStorageDate);
                } else if (Array.isArray(yearMaster) && yearMaster.length > 0) {
                    setSeasonSelected(yearMaster[0]?.desc_en);
                }
            } catch (error) {
                console.error('Failed to fetch local storage data:', error);
            }
        };

        fetchLocalStorageData();
    }, []);

    const handleToRedirectLocation = (path, param = null) => {
        navigate(path, param ? { state: { param, 'seasonDate':seasonSelected } } : {});
    };
    const handleSeasonDateSelection = (season) => {
        setSeasonSelected(season);
        storeData('seasonDate', season);
    };

    const fetchAndSetTonnageList = async () => {
        const actualData = {
            Registration_Season: seasonSelected,
            Farmer_Code: farmerCode,
        };

        try {
            const tonnageResponse = await getApiDiffHeaders('tonnage/', actualData);

            // Check if response is valid and contains expected data
            if (tonnageResponse && tonnageResponse.registration_wise) {
                const registrationArray = Object.values(tonnageResponse.registration_wise);
                setRegistrationList(registrationArray);
                setTotalTonnage(tonnageResponse.total?.Total_Tonnage ?? null); // Optional chaining for safety
            } else {
                // Handle case where response structure is not as expected
                setRegistrationList([]); // Or another appropriate default state
                setTotalTonnage(null);
                console.warn('Unexpected response structure:', tonnageResponse);
            }
        } catch (error) {
            console.error('Failed to fetch tonnage data:', error);
            // Optionally, set an error state to display to the user
        }
    };

    return (
        <>
            <div className="Tenders-headbanner">
                <div className="Farmer-banner col-md-12">
                    <div className="container">
                        <h1 className="headingtenders font-bold">{bannerInfo.heading}</h1>
                        <div className="breadcrum">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb bg-container">
                                    <li className="breadcrumb-item brdm-itm">
                                        <button
                                            onClick={() => handleToRedirectLocation('/home')}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: 'inherit', // Use inherited color from parent
                                                textDecoration: 'underline', // Makes it look like a link
                                                cursor: 'pointer',
                                            }}
                                            tabIndex={0} // Make it focusable for keyboard users
                                        >
                                            {bannerInfo.link}
                                        </button>
                                    </li>
                                    <li
                                        className="breadcrumb-item brdm-itm"
                                        aria-current="page">
                                        <button
                                            onClick={() => handleToRedirectLocation('/fc-dashboard')}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: 'inherit', // Use inherited color from parent
                                                textDecoration: 'underline', // Makes it look like a link
                                                cursor: 'pointer',
                                            }}
                                            tabIndex={0} // Make it focusable for keyboard users
                                        >
                                            {bannerInfo.text}
                                        </button>
                                    </li>
                                    <li
                                        className="breadcrumb-item brdm-itm active"
                                        aria-current="page">
                                        {bannerInfo.text2}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <section className="tender-table">
                <div className="tendermain-table">
                    <div className="col-md-12">
                        <div className="famrer-logout mb-3">
                            <button
                                onClick={() => {
                                    handleToRedirectLocation('/fc-dashboard')
                                }}
                                className="btn btn-default btn-round tender-Register btn-back"
                                >
                                    <img
                                        className="back"
                                        alt="back"
                                        src={getAssetPath("assets/icons/back-arrow.png")}
                                    ></img>
                                    {registerTable.back}
                                </button>
                        </div>
                        <div className="login-search mb-30">
                            <div className="farmer-name">
                                <div className="username">
                                    <h2 className="font-bold">
                                        {registerTable.tablename}
                                    </h2>
                                </div>
                                <p className="dash-text regular-font">
                                    {registerTable.para}: {totalTonnage}
                                </p>
                            </div>
                            <div className="season-selectn">
                                <p className="selectreg-title font-regular">
                                    {registerTable.selectTitle}
                                </p>

                                <div className="dropdown">
                                    <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Season {seasonSelected}
                                    </button>
                                    <ul
                                        className="dropdown-menu drpdown-height"
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        {storedYearMaster && storedYearMaster.map((season) => (
                                            <li key={season.season_yr_id} >
                                                <button
                                                    className="dropdown-item"
                                                    onClick={() => handleSeasonDateSelection(season.desc_en)}
                                                >
                                                    Season {season.desc_en}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive mb-3">
                        <table className="table table-striped table-hover ">
                                <thead className="thead-dark th-height">
                                <tr>
                                    <th scope="col">Total Tonnage</th>
                                    <th scope="col">Survey Number</th>
                                    <th scope="col" >
                                        Harvesting Number
                                    </th>
                                    <th scope="col">Cane Class Type</th>
                                    <th scope="col">Plantation Type</th>
                                    <th scope="col">Plantation Date</th>
                                    <th scope="col">Cane Area</th>
                                    <th scope="col">Details</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Array.isArray(registrationList) && registrationList.length > 0 ? (
                                    registrationList.map((singleReg) => (
                                        <tr className="tr-height"
                                            key={singleReg.Total_Tonnage}> {/* Make sure to use a unique key */}
                                            <td>{singleReg.Total_Tonnage || 'N/A'}</td>
                                            <td>{singleReg.Survey_Number || 'N/A'}</td>
                                            <td>{singleReg.Harvesting_Number || 'N/A'}</td>
                                            <td>{singleReg.Cane_Class_Type || 'N/A'}</td>
                                            <td >{singleReg.Plantation_Type || 'N/A'}</td>
                                            <td>{singleReg.Plantation_Date || 'N/A'}</td>
                                            <td>{singleReg.Cane_Area || 'N/A'}</td>
                                            <td>
                                                    <button
                                                        onClick={() => {handleToRedirectLocation('/fc-weight-detail',singleReg)}}
                                                        className="btn btn-default btn-round Download">
                                                        {registerTable.buttons.viewdetail}
                                                    </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8">
                                            <p className="result-text text-center regular-font">No Sugarcane Weights found</p>
                                        </td>
                                    </tr>

                                )}
                                </tbody>
                            </table>
                        </div>
                        {
                            Array.isArray(registrationList) && registrationList.length > 0 ?
                                <p className="result-text text-center regular-font">{registerTable.results.text1} {registrationList.length} {registerTable.results.text2}</p>
                                :null
                        }

                    </div>
                </div>
            </section>
        </>
    )
}

export default FcCaneWeight
