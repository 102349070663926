import axios from 'axios';
import { getLocalStorageData } from "../localStorageService";

const BASE_URL = 'https://krantisakhar.com/farmer-corner-api/';

// Initialize axios instance for general requests (without token)
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});

// Function to get stored user data and return axios instance with token
const getAxiosInstanceWithToken = () => {
    const storedUserData = JSON.parse(getLocalStorageData('userDetails')) ?? null;

    return axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': storedUserData && storedUserData.token
                ? 'Token ' + storedUserData.token
                : null,
        },
    });
};

// Function to handle GET requests
export const getApi = async (endpoint, params = {}) => {
    try {
        const response = await axiosInstance.get(endpoint, { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

// Function to handle GET requests with authorization
export const getApiDiffHeaders = async (endpoint, params = {}) => {
    const axiosInstanceWithToken = getAxiosInstanceWithToken();
    try {
        const response = await axiosInstanceWithToken.get(endpoint, { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

// Function to handle POST requests
export const postApi = async (endpoint, data) => {
    try {
        const response = await axiosInstance.post(endpoint, data);
        return response.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

// Function to handle POST requests with authorization
export const postApiWithAuth = async (endpoint, data) => {
    const axiosInstanceWithToken = getAxiosInstanceWithToken();
    try {
        const response = await axiosInstanceWithToken.post(endpoint, data);
        return response.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

// Function to handle PUT requests
export const putApi = async (endpoint, data) => {
    try {
        const response = await axiosInstance.put(endpoint, data);
        return response.data;
    } catch (error) {
        console.error('Error updating data:', error);
        throw error;
    }
};

export const PUBLIC_BASE_URL = `${process.env.PUBLIC_URL}/`;
