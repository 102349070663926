import React, {useEffect} from "react";
import "./TenderLogin.css";
import {useState} from "react";
import {json, useLocation, useNavigate} from "react-router-dom";
import {useLoginQueryMutation} from "../../services/userAuthApi";
import {
    storeData,
    getLocalStorageData, removeKeyData,
} from "../../services/localStorageService";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {postApi} from "../../services/api/api";
import {getAssetPath} from "../../services/helpers";

const TenderLogin = () => {

    const {t} = useTranslation();
    const location = useLocation();
    //if user redirect from FarmerInfo Page then we use show page depending on condition
    const {fromPage = null} = location.state || {};
    const {form, requiredMsg} = t("login");
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    // 1 for code screen view, 2 for otp screen view
    const [farmerFormDiv, setFarmerFormDiv] = useState(1); //default 1
    const storedUserData = JSON.parse(getLocalStorageData('userDetails')) ?? null;
    const [farmerCode, setFarmerCode] = useState(null);
    const [farmerData, setFarmerData] = useState(null);
    const [otp, setOtp] = useState(null);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        setFarmerData(storedUserData);
        console.log('storedUserData', storedUserData);
        const token = getLocalStorageData("token");

        if (token) {
            const roleId = getLocalStorageData("roleId");
            if (roleId === "1") {
                navigate("/FarmerDetails");
            } else if (roleId === "2") {
                navigate("/Tenders");
            }
        }
    }, []);

    const [error, setError] = useState({
        status: false,
        msg: "",
        type: "",
    });
    const [loginUser] = useLoginQueryMutation();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const actualData = {
            email: data.get("email"),
            password: data.get("password"),
            role_id: data.get("role_id"),
            agree_terms: data.get("agree_terms") === "true" ? 1 : 0,
            lang: i18next.language
        };
        if (actualData.email && actualData.password && actualData.agree_terms) {
            const res = await loginUser(actualData);
            if (res.data && res.data.message === "success") {
                storeData("token", res.data.data.token);
                storeData("roleId", res.data.data.user_details.role_id);
                storeData(
                    "userName",
                    res.data.data.user_details.name.charAt(0).toUpperCase() +
                    res.data.data.user_details.name.slice(1)
                );

                if (res.data.data.user_details.role_id === 2) {
                    navigate("/Tenders");
                } else {
                    navigate("/FarmerDetails");
                }
            }
            if (res.error) {
                setError({status: true, msg: res.error.data.message, type: "error"});

                setTimeout(function () {
                    setError({status: false, msg: "", type: "error"});
                }, 7000);
            }
        } else {
            setError({status: true, msg: requiredMsg, type: "error"});

            setTimeout(function () {
                setError({status: false, msg: "", type: "error"});
            }, 7000);
        }
    };

    const farmerCodeSubmit = async (e) => {
        e.preventDefault(); // Prevent form submission

        // Extract farmer code from form data
        const data = new FormData(e.currentTarget);
        const actualData = {
            farmer_code: data.get("farmercode"),
        };

        try {
            // Send API request to generate OTP
            const generateOtpResponse = await postApi('generateotp/', actualData);

            if (generateOtpResponse.success) {
                handleSuccess(actualData.farmer_code);
            } else {
                handleError(generateOtpResponse.message);
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

// Handle success response
    const handleSuccess = (farmerCode) => {
        setFarmerCode(farmerCode);
        setFarmerFormDiv(2);
        setOtp(''); // Clear OTP input
    };

// Handle error response
    const handleError = (message) => {
        setError({ status: true, msg: message, type: "error" });

        setTimeout(() => {
            setError({ status: false, msg: "", type: "error" });
        }, 7000);
    };
    const farmerOtpSubmit = async (e) => {
        e.preventDefault(); // Prevent form submission

        // Extract OTP and farmer code from form data
        const data = new FormData(e.currentTarget);
        const actualData = {
            otp: data.get("otp"),
            farmer_code: data.get("farmercode") ?? farmerCode,
        };


        try {
            // Send API request to verify OTP
            const verifyOtpResponse = await postApi('verifyotp/', actualData);

            if (verifyOtpResponse.token) {
                setFarmerFormDiv(1);
                storeData('userDetails', JSON.stringify(verifyOtpResponse));
                storeData('farmerCode', farmerCode);
                navigate("/fc-dashboard");
            } else {
                handleError(verifyOtpResponse.message);
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };
    const handleOtpChange = (e) => {
        const newValue = e.target.value.replace(/\D/, ''); // Remove any non-numeric characters
        setOtp(newValue); // Update the state with the numeric value
    };

    const gotoFarmerDashboard = () => {
        navigate("/fc-dashboard");
    };

    return (
        <>
            <section className="login-bg">
                <div className="slider-login fslogin-bg">
                    <div className="login-content">
                        <div className="container">
                            <div className="container login-form-row col-md-6 mg-tp">
                                <p className="col-12 txt-center font-bold">{form.supplierForm.heading}</p>
                                {/* <ul
                                    className="nav nav-pills justify-content-center"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <div className="bg-pill">
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${fromPage !== 'FarmerInfo' ? ' active' : ''}`}
                                                id="Supplier-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#Supplier"
                                                type="button"
                                                role="tab"
                                                aria-controls="Supplier"
                                                aria-selected={`${fromPage !== 'FarmerInfo' ? ' true' : 'false'}`}
                                            >
                                                {form.supplierForm.btn1}
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${fromPage === 'FarmerInfo' ? ' active' : ''}`}
                                                id="Farmer-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#Farmer"
                                                type="button"
                                                role="tab"
                                                aria-controls="Farmer"
                                                aria-selected={`${fromPage === 'FarmerInfo' ? ' true' : 'false'}`}
                                            >
                                                {form.supplierForm.btn2}
                                            </button>
                                        </li>
                                    </div>
                                </ul> */}
                                <div className="tab-content custom-tb-cnt" id="myTabContent">
                                    {error.status ? (
                                        <div className="alert alert-danger">{error.msg}</div>
                                    ) : (
                                        ""
                                    )}
                                    {/* <div
                                        className={`tab-pane fade ${fromPage !== 'FarmerInfo' ? 'show active' : ''}`}
                                        id="Supplier"
                                        role="tabpanel"
                                        aria-labelledby="Supplier-tab"
                                    >
                                        <form id="supplier-form" onSubmit={handleSubmit}>
                                            <input type="hidden" name="role_id" value="2"/>
                                            <div className="input-grp">
                                                <img
                                                    className="user-icon"
                                                    alt="icon"
                                                    src="assets/icons/user-icon.png"
                                                ></img>
                                                <input
                                                    placeholder={form.supplierForm.username}
                                                    id="email"
                                                    name="email"
                                                    className="form-control rounded-0 font-regular"
                                                    type="text"
                                                />
                                            </div>
                                            <div className="input-grp">
                                                <img
                                                    className="user-icon"
                                                    alt="icon"
                                                    src="assets/icons/password-icon.png"
                                                ></img>
                                                <input
                                                    placeholder={form.supplierForm.password}
                                                    id="password"
                                                    name="password"
                                                    className="form-control rounded-0 font-regular"
                                                    type={showPassword ? "text" : "password"}
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-round show-hide-btn"
                                                    onClick={togglePasswordVisibility}
                                                >
                                                    {showPassword ? (
                                                        <img
                                                            className="show-pass"
                                                            alt="icon"
                                                            src="assets/icons/show-pass.png"
                                                        />
                                                    ) : (
                                                        <img
                                                            className="hide-pass"
                                                            alt="icon"
                                                            src="assets/icons/hide-pass.png"
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                            <label className="mb-3">
                                                <a href="resetLink">{form.supplierForm.forgotPass}</a>
                                            </label>
                                            <div className="form-check mb-4">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={true}
                                                    id="agree_terms"
                                                    name="agree_terms"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckDefault"
                                                >
                                                    {form.supplierForm.T_C1} <a href="">{form.supplierForm.T_C2}.</a>
                                                </label>
                                            </div>
                                            <button
                                                className="btn btn-default btn-round learn-more-2 w-100 mb-3 font-bold">
                                                {form.supplierForm.loginBtn}
                                                <img
                                                    className="Arrow-1"
                                                    alt="icon"
                                                    src="assets/icons/Arrow-1.png"
                                                ></img>
                                            </button>
                                        </form>
                                        <p className="register-link">
                                            {form.supplierForm.text1} <a
                                            href="TenderRegister">{form.supplierForm.text2}</a>
                                        </p>
                                    </div> */}

                                    <div
                                        className={`tab-pane fade ${fromPage === 'FarmerInfo' ? 'show active' : ''}`}
                                        id="Farmer"
                                        role="tabpanel"
                                        aria-labelledby="Farmer-tab"
                                    >
                                        {/*/!* farmer code section *!/   or*/}
                                        {/* farmer OTP section */}
                                        {
                                            farmerData == null ?
                                                farmerFormDiv === 1 ?
                                                    <div className="farmercode">
                                                        <p className="text-center mb-4">{form.farmerForm.usertext}</p>
                                                        <form id="farmer-form" onSubmit={farmerCodeSubmit}>
                                                            <input type="hidden" name="role_id" value="1"/>
                                                            <div className="input-grp">
                                                                <img
                                                                    className="user-icon"
                                                                    alt="icon"
                                                                    src="assets/icons/user-icon.png"
                                                                ></img>
                                                                <input
                                                                    placeholder={form.farmerForm.username}
                                                                    id="farmercode"
                                                                    name="farmercode"
                                                                    className="form-control rounded-0 font-regular"
                                                                    type="text"
                                                                    maxLength="6"
                                                                    pattern="\d{6}"
                                                                    title="Please enter exactly 5 digits"
                                                                    required
                                                                    onInput={(e) => e.target.value = e.target.value.replace(/\D/, '')}  // Remove any non-numeric characters
                                                                />
                                                            </div>
                                                            <button
                                                                className="btn btn-default btn-round learn-more-2 w-100 mb-3 font-bold">
                                                                {form.farmerForm.btn}
                                                                <img
                                                                    className="Arrow-1"
                                                                    alt="icon"
                                                                    src="assets/icons/Arrow-1.png"
                                                                ></img>
                                                            </button>
                                                        </form>
                                                    </div>
                                                    :
                                                    <div className="farmerotp">
                                                        <p className="text-center mb-4">{form.farmerForm.usertext2}</p>
                                                        <form id="farmer-form" onSubmit={farmerOtpSubmit}>
                                                            <input type="hidden" name="farmer_code" value={farmerCode}/>
                                                            <div className="input-grp">
                                                                <img
                                                                    className="user-icon"
                                                                    alt="icon"
                                                                    src="assets/icons/user-icon.png"
                                                                ></img>
                                                                <input
                                                                    placeholder={form.farmerForm.password}
                                                                    id="otp"
                                                                    name="otp"
                                                                    className="form-control rounded-0 font-regular"
                                                                    type="text"
                                                                    maxLength="8"
                                                                    pattern="\d{8}"
                                                                    value={otp}
                                                                    onChange={handleOtpChange} // Handle input changes
                                                                />
                                                            </div>
                                                            <button
                                                                className="btn btn-default btn-round learn-more-2 w-100 mb-3 font-bold">
                                                                {form.farmerForm.btn2}
                                                                <img
                                                                    className="Arrow-1"
                                                                    alt="icon"
                                                                    src="assets/icons/Arrow-1.png"
                                                                ></img>
                                                            </button>
                                                        </form>
                                                    </div>
                                                :
                                                <div className="col-md-2 mx-auto mt-3" onClick={() => {
                                                    gotoFarmerDashboard()
                                                }}>
                                                    <a>
                                                        <button
                                                            className="btn btn-default btn-round learn-more-2 w-300 mb-3 font-bold">
                                                            Go to the Farmer Dashboard
                                                            <img
                                                                className="Arrow-1"
                                                                alt="icon"
                                                                src={getAssetPath("assets/icons/Arrow-1.png")}
                                                            ></img>
                                                        </button>
                                                    </a>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TenderLogin;
