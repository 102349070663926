import React from "react";
import "./CaneDevelopment.css";
import { getAssetPath } from "../../services/helpers";

import { useTranslation } from "react-i18next";

const CaneDevelopment = () => {
  const { t } = useTranslation();

  const { banner, developmentSetion, container1, container2, container3 } =
    t("caneDevelopment");

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="canedeve-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">
              {banner.heading1} <br />
              {banner.heading2}
            </h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="simple-idea abt-pdtop cane-deve-bg" style={{paddingBottom : '70px'}}>
        <div className="row mrg-0">
          <div className="container col-md-6">
            <h2 className="idea-title">{developmentSetion.heading}</h2>
          </div>

        </div>
        <div className="container">
        <div className="container col-md-10">
            <p className="idea-para mt-1">{developmentSetion.para1}</p>
          </div>
            <div className="row">
              <div className="col-md-4">
                <div className="abt-facility-card">
                  <div className="abt-facility-content">
                    <div className="abt-facility-name-icon">
                      <div className="abt-facility-icon">
                        <img
                        alt="cane-dev-icon"
                        src={getAssetPath("assets/icons/canedeve-icon1.png")}
                        ></img>
                      </div>
                      <p className="abt-facility-name font-semibold">{developmentSetion.para2}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="abt-facility-card">
                  <div className="abt-facility-content">
                    <div className="abt-facility-name-icon">
                      <div className="abt-facility-icon">
                        <img
                        alt="cane-dev-icon"
                        src={getAssetPath("assets/icons/canedeve-icon2.png")}
                        ></img>
                      </div>
                      <p className="abt-facility-name font-semibold">{developmentSetion.para3}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="abt-facility-card">
                  <div className="abt-facility-content">
                    <div className="abt-facility-name-icon">
                      <div className="abt-facility-icon">
                        <img
                        alt="cane-dev-icon"
                        src={getAssetPath("assets/icons/canedeve-icon3.png")}
                        ></img>
                      </div>
                      <p className="abt-facility-name font-semibold">{developmentSetion.para4}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="abt-facility-card">
                  <div className="abt-facility-content">
                    <div className="abt-facility-name-icon">
                      <div className="abt-facility-icon">
                        <img
                        alt="cane-dev-icon"
                        src={getAssetPath("assets/icons/canedeve-icon4.png")}
                        ></img>
                      </div>
                      <p className="abt-facility-name font-semibold">{developmentSetion.para5}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="abt-facility-card">
                  <div className="abt-facility-content">
                    <div className="abt-facility-name-icon">
                      <div className="abt-facility-icon">
                        <img
                        alt="cane-dev-icon"
                        src={getAssetPath("assets/icons/canedeve-icon5.png")}
                        ></img>
                      </div>
                      <p className="abt-facility-name font-semibold">{developmentSetion.para6}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="abt-facility-card">
                  <div className="abt-facility-content">
                    <div className="abt-facility-name-icon">
                      <div className="abt-facility-icon">
                        <img
                        alt="cane-dev-icon"
                        src={getAssetPath("assets/icons/canedeve-icon6.png")}
                        ></img>
                      </div>
                      <p className="abt-facility-name font-semibold">{developmentSetion.para7}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
      <section className="candeve-content-bg">
        <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                <h4 className="cane-development-title font-bold">
                  {container1.title}
                </h4>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath("assets/imgs/canedeve-img/candeve1.jpg")}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container1.cards.card1.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container1.cards.card1.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath(
                        "assets/imgs/canedeve-img/candeve2.jpg"
                      )}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container1.cards.card2.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container1.cards.card2.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath("assets/imgs/canedeve-img/candeve3.jpg")}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container1.cards.card3.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container1.cards.card3.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath("assets/imgs/canedeve-img/candeve4.jpg")}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container1.cards.card4.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container1.cards.card4.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath(
                        "assets/imgs/canedeve-img/candeve5.jpg"
                      )}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container1.cards.card5.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container1.cards.card5.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath(
                        "assets/imgs/canedeve-img/candeve6.jpg"
                      )}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container1.cards.card6.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container1.cards.card6.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath("assets/imgs/canedeve-img/candeve7.jpg")}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container1.cards.card7.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container1.cards.card7.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath("assets/imgs/canedeve-img/candeve8.jpg")}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container1.cards.card8.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container1.cards.card8.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath("assets/imgs/canedeve-img/candeve15.jpg")}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container1.cards.card9.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container1.cards.card9.para}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Facilities provided for increasing sugarcane production */}
            <div className="row mt-5">
              <div className="col-md-12">
                <h4 className="cane-development-title font-bold">
                  {container2.title}
                </h4>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath(
                        "assets/imgs/canedeve-img/candeve9.jpg"
                      )}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container2.cards.card1.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container2.cards.card1.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath(
                        "assets/imgs/canedeve-img/candeve10.jpg"
                      )}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container2.cards.card2.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container2.cards.card2.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath(
                        "assets/imgs/canedeve-img/candeve11.jpg"
                      )}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container2.cards.card3.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container2.cards.card3.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath("assets/imgs/canedeve-img/candeve12.jpg")}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container2.cards.card4.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container2.cards.card4.para}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Farmer Training and Honor Award  */}
            <div className="row mt-5">
              <div className="col-md-12">
                <h4 className="cane-development-title font-bold">
                  {container3.title}
                </h4>
              </div>
              <div className="col-md-6">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath(
                        "assets/imgs/canedeve-img/candeve13.jpg"
                      )}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container3.cards.card1.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container3.cards.card1.para}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="deve-facility">
                  <div className="cane-facility-img">
                    <img
                      className="img-fluid"
                      alt="deve-facility"
                      src={getAssetPath(
                        "assets/imgs/canedeve-img/candeve14.jpg"
                      )}
                    ></img>
                  </div>
                  <div className="facility-info ">
                    <h4 className="facility-name font-bold">
                      {container3.cards.card2.heading}
                    </h4>
                    <p className="facility-desc font-regular">
                      {container3.cards.card2.para}
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default CaneDevelopment;
