import React, {useEffect, useState, useRef} from "react";
import './Home.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import {useGetGalleryImagesDetailQuery, useGetHomePageDetailQuery} from "../../services/userAuthApi";
import {useNavigate} from "react-router-dom";
import {getLocalStorageData, storeData} from "../../services/localStorageService";
import {useTranslation} from "react-i18next";
import {downloadClick} from "../../services/commonFunction"
import {getAssetPath} from "../../services/helpers";
import {imageNames} from "./staticImageArray";
import i18next from "i18next";
import {getApi, getApiDiffHeaders, postApi} from "../../services/api/api";


const Home = () => {
    const navigate = useNavigate()
    const [bannerFirstImage, setBannerFirstImage] = useState('');
    const [bannerSecondImage, setBannerSecondImage] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [latestTender, setLatestTender] = useState([]);
    const [noticeList, setNoticeList] = useState('');  //notice board
    const storedYearMaster = getLocalStorageData('seasonYears') ? JSON.parse(getLocalStorageData('seasonYears')) : null;
    const [seasonSelected, setSeasonSelected] = useState(null);
    const [data, setData] = useState([]);
    const [galleryImageList, setGalleryImageList] = useState([]);  //notice board

    const {data: homePageResponse, isSuccess} = useGetHomePageDetailQuery(i18next.language);
    const {data: galleryImagesResponse, isSuccessImages} = useGetGalleryImagesDetailQuery();

    useEffect(() => {
        if (homePageResponse && isSuccess) {
            const response = homePageResponse.data;
            setBannerFirstImage(response.bannerDetail.banner_image_first);
            setBannerSecondImage(response.bannerDetail.banner_image_second);
            setTitle(response.bannerDetail.title);
            setDescription(response.bannerDetail.description);
            setLatestTender(response.latestTender);
            setNoticeList(response.notice);
        }
    }, [homePageResponse, isSuccess])

    useEffect(() => {
        if (galleryImagesResponse && galleryImagesResponse.code === 200) {
            const images = galleryImagesResponse.data.filter(item => item.type === 1);
            setGalleryImageList(images);
        }
    }, [galleryImagesResponse])

    useEffect(() => {
        fetch('./assets/data.json')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setSeasonSelected(data[data.length - 1]);
                const reversedData = data.slice().reverse();
                setData(reversedData);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        // console.log(storedYearMaster);
        if (!storedYearMaster || Object.keys(storedYearMaster).length === 0) {
            callSeasonApi().catch((error) => console.error("Error in callSeasonApi:", error));
        }

    }, []);

    const callSeasonApi = async () => {
        try {
            // Fetch the seasonyears details from the API
            const getYearMaster = await getApi('seasonyears/');
            if (getYearMaster) {
                let createSeasonYears = JSON.stringify(getYearMaster);
                await storeData('seasonYears', createSeasonYears ?? null); // No await if storeData is not async
            }
        } catch (error) {
            console.error('Failed to fetch tonnage details:', error);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {

        }, 2000); // 2 seconds

        return () => clearTimeout(timer); // Cleanup the timeout
    }, []);

    const handleButtonClick = () => {
        if (getLocalStorageData('token') && parseInt(getLocalStorageData("roleId"), 10) === 2) {
            navigate('Tenders')
        } else {
            navigate('OfflineTender')
        }
    }
    const handleDownload = (notice) => {
        const url = notice.attachment;
        let fileName = url.substring(url.lastIndexOf('/') + 1);
        downloadClick(url, fileName);
    }

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    //leadership mobile slider btn
    let mobRef = useRef(null);
    const mobnext = () => {
        mobRef.slickNext();
    };
    const mobprevious = () => {
        mobRef.slickPrev();
    };
    const handleTenderAttachment = (tender) => {
        const url = tender.attachment[0].attachment
        let fileName = url.substring(url.lastIndexOf("/") + 1);
        downloadClick(url, fileName)
    }
    var overviewslider = {
        autoplay: false,
        dots: true,
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 5,
        gap: 10,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true
                }
            },
        ]
    }

    var leadershipslider = {
        autoplay: false,
        dots: false,
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: true
                }
            }
        ]
    }
    var leadershipmobslider = {
        autoplay: false,
        dots: false,
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    const {t} = useTranslation();
    const {
        homeBanner,
        companyOverview,
        CompanySlider,
        tenderSection,
        facilitySection,
        leadershipSection,
        newsRoom
    } = t('homePage')

    // counter animation
    const [counterOn, setCounterOn] = useState(false);

    // dynamic modal gallery images
    const [selectedImage, setSelectedImage] = useState(null);
    const openModal = (imageName) => {
        setSelectedImage(imageName);
    }

    return (
        <>
            {/* banner-section */}
            {/* <section className="home-banner">
            <div className="slider col-md-12">
                <img className="banner-img" src={bannerFirstImage} />
                <div className="section-heading">
                    <div className="inner-sec container">
                        <p className="homebanner-sbtxt">{homeBanner.sbtxt}</p>
                        <h1 className="homebanner-heading">{homeBanner.heading}</h1>
                    </div>
                </div>
            </div>
        </section> */}

            <div className="home-banner">
                <div className="main-home-banner col-md-12">
                    <div className="inner-sec mob-inner-sec container">
                        <p className="homebanner-sbtxt">{homeBanner.sbtxt}</p>
                        <h1 className="homebanner-heading">{homeBanner.heading}</h1>
                    </div>
                </div>
            </div>

            {/* simple idea section */}
            <section className="simple-idea simple-idea-parallex" style={{padding: "100px 0"}}>
                <div className="container">
                    <div className="row ">
                        <div className="col-md-5 mobile-pd">
                            <div className="simple-idea-text mb-5" dangerouslySetInnerHTML={{__html: title}}/>
                            <div className="pdr">
                                <p className="idea-para ip-mb" style={{textAlign: "left"}}>
                                    {description}
                                </p>
                            </div>
                            <div className="view-more">
                                <a href="AboutUs">
                                    <button className="btn btn-default btn-round learn-more-2 ">{newsRoom.learnMore}
                                        <img className="Arrow-1" alt="arrow"
                                             src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-7 mobile-pd">
                            {/* <img className="simpleidea-img" src={bannerSecondImage}></img> */}
                            <img className="simpleidea-img si-fill-img" alt="simple-idea"
                                 src={getAssetPath("assets/imgs/simple-idea.png")}></img>
                        </div>
                    </div>
                </div>
            </section>

            {/* company-overview section */}
            {/* <section className="company-overview co-newbg company-overview-parallex">
            <div className="cmpn-over-wrp-new no-rad">
                <div className="container cmpn-details mobile-pd">
                    <div className="col-md-12">
                        <p className="pd-subtxt font-regular">{companyOverview.pdSubtxt}</p>
                    </div>
                    <div className="col-md-12">
                        <h2 className="co-title font-bold">{companyOverview.pdTitle}</h2>
                    </div>
                        <div className="data-row">
                        <Slider {...overviewslider}>
                            <div className="col-md-2">
                                <div className="glass-bg gap-itm">
                                    <p className="crushing font-medium">{CompanySlider.crushingOne.para1}<br/>{CompanySlider.crushingOne.paraone}</p>
                                    <h4 className="font-bold crushing-value">{CompanySlider.crushingOne.crushingValue}</h4>
                                    <p className="fy-year font-regular">{CompanySlider.crushingOne.para2}</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="glass-bg gap-itm">
                                <p className="crushing font-medium">{CompanySlider.crushingTwo.para1}<br/>{CompanySlider.crushingTwo.paraone}</p>
                                    <h4 className="font-bold crushing-value">{CompanySlider.crushingTwo.crushingValue}</h4>
                                    <p className="fy-year font-regular">{CompanySlider.crushingTwo.para2}</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="glass-bg gap-itm">
                                <p className="crushing font-medium">{CompanySlider.crushingThree.para1}<br/>{CompanySlider.crushingThree.paraone}</p>
                                    <h4 className="font-bold crushing-value">{CompanySlider.crushingThree.crushingValue}</h4>
                                    <p className="fy-year font-regular">{CompanySlider.crushingThree.para2}</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="glass-bg gap-itm">
                                <p className="crushing font-medium">{CompanySlider.crushingFour.para1}</p>
                                    <h4 className="font-bold crushing-value">{CompanySlider.crushingFour.crushingValue}</h4>
                                    <p className="fy-year font-regular">{CompanySlider.crushingFour.para2}</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="glass-bg gap-itm">
                                <p className="crushing font-medium">{CompanySlider.crushingFive.para1}</p>
                                    <h4 className="font-bold crushing-value">{CompanySlider.crushingFive.crushingValue}</h4>
                                    <p className="fy-year font-regular">{CompanySlider.crushingFive.para2}</p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section> */}

            <section className="new-compnayover-bg">
                <div className="container">
                    <div className="row d-flex align-items-start">
                        <div className="col-md-4 ovr-pd-lft">
                            <p className="pd-newmintxt font-medium">{companyOverview.pdmintxt}</p>
                            <h2 className="co-newtitle font-bold mb-4">{companyOverview.pdTitle}</h2>
                            <p className="pd-newsubtxt font-regular">{companyOverview.pdSubtxt}</p>
                        </div>
                        <div className="offset-md-1 col-md-7 ovr-pd-lft">
                            <p className="pd-newsubtxt font-regular">{companyOverview.peryear}</p>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="company-overview-card">
                                                <div className="icon-data">
                                                    <img className="coc-icon" alt="coc-doc"
                                                         src={getAssetPath("assets/icons/capacity.png")}></img>
                                                    <ScrollTrigger onEnter={() => setCounterOn(true)}
                                                                   onExit={() => setCounterOn(false)}>
                                                        <h4 className="font-bold crushing-value-new">
                                                            {counterOn && <CountUp
                                                                end={seasonSelected ? seasonSelected.crushing_day : 0}
                                                                duration={2} delay={0}/>}
                                                        </h4>
                                                    </ScrollTrigger>
                                                </div>
                                                <div className="coc-title">
                                                    <p className="crushing-sbtxt font-medium">{CompanySlider.crushingOne.para1}<br/>{CompanySlider.crushingOne.paraone}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="company-overview-card last">
                                                <div className="icon-data">
                                                    <img className="coc-icon" alt="coc-doc"
                                                         src={getAssetPath("assets/icons/sugarcane-rate.png")}></img>
                                                    <ScrollTrigger onEnter={() => setCounterOn(true)}
                                                                   onExit={() => setCounterOn(false)}>
                                                        <h4 className="font-bold crushing-value-new">
                                                            {counterOn && <CountUp
                                                                end={seasonSelected ? seasonSelected.sugar_cane_rate : 0}
                                                                duration={2} useIndianSeparators={true} delay={0}/>}
                                                        </h4>
                                                    </ScrollTrigger>
                                                </div>
                                                <div className="coc-title">
                                                    <p className="crushing-sbtxt font-medium">{CompanySlider.crushingFive.para1}<br/>{CompanySlider.crushingFive.paraone}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="company-overview-card last">
                                                <div className="icon-data">
                                                    <img className="coc-icon" alt="coc-doc"
                                                         src={getAssetPath("assets/icons/sugar-production.png")}></img>
                                                    <ScrollTrigger onEnter={() => setCounterOn(true)}
                                                                   onExit={() => setCounterOn(false)}>
                                                        <h4 className="font-bold crushing-value-new">
                                                            {counterOn && <CountUp
                                                                end={seasonSelected ? seasonSelected.sugar_product : 0}
                                                                duration={3} delay={0}/>}
                                                        </h4>
                                                    </ScrollTrigger>
                                                </div>
                                                <div className="coc-title">
                                                    <p className="crushing-sbtxt font-medium">{CompanySlider.crushingThree.para1}<br/>{CompanySlider.crushingThree.paraone}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mrg-common">
                                        <div className="col-md-3">
                                            <div className="company-overview-card">
                                                <div className="icon-data">
                                                    <img className="coc-icon" alt="coc-doc"
                                                         src={getAssetPath("assets/icons/sugar-recovery.png")}></img>
                                                    <ScrollTrigger onEnter={() => setCounterOn(true)}
                                                                   onExit={() => setCounterOn(false)}>
                                                        <h4 className="font-bold crushing-value-new">
                                                            {counterOn && <CountUp
                                                                end={seasonSelected ? seasonSelected.sugar_recovery : 0}
                                                                duration={2} delay={0} decimals={2}/>}
                                                        </h4>
                                                    </ScrollTrigger>
                                                </div>
                                                <div className="coc-title">
                                                    <p className="crushing-sbtxt font-medium">{CompanySlider.crushingFour.para1}<br/>{CompanySlider.crushingFour.paraone}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="company-overview-card">
                                                <div className="icon-data">
                                                    <img className="coc-icon" alt="coc-doc"
                                                         src={getAssetPath("assets/icons/cane-crushed.png")}></img>
                                                    <ScrollTrigger onEnter={() => setCounterOn(true)}
                                                                   onExit={() => setCounterOn(false)}>
                                                        <h4 className="font-bold crushing-value-new">
                                                            {counterOn && <CountUp
                                                                end={seasonSelected ? seasonSelected.cane_crushed : 0}
                                                                duration={3} delay={0} decimals={3}/>}
                                                        </h4>
                                                    </ScrollTrigger>
                                                </div>
                                                <div className="coc-title">
                                                    <p className="crushing-sbtxt font-medium">{CompanySlider.crushingTwo.para1}<br/>{CompanySlider.crushingTwo.paraone}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Facility section */}
            <section className="new-facility-bg ">
                <div className="container">
                    <div className="col-md-6" style={{margin: '0 auto'}}>
                        <h2 className="facility-main-title mb-3">{facilitySection.investorTitle}</h2>
                        <p className="facility-main-sbtxt font-medium">{facilitySection.investorDescription}</p>
                    </div>
                </div>
                {/* desktop-view */}
                <div className="desktop-view">
                    <div className="container mb-4 mt-5 desktop-view">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="tab-content tab-padding" id="v-pills-tabContent">
                                            <div className="tab-pane fade show active" style={{height: "100%"}}
                                                 id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                <div className="img-hght">
                                                    <img className="new-facility-img1" alt="facility1"
                                                         src={getAssetPath("assets/imgs/new-home-facility1.png")}></img>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" style={{height: "100%"}} id="v-pills-profile"
                                                 role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                <div className="img-hght">
                                                    <img className="new-facility-img1" alt="facility2"
                                                         src={getAssetPath("assets/imgs/new-home-facility3.png")}></img>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" style={{height: "100%"}}
                                                 id="v-pills-settings" role="tabpanel"
                                                 aria-labelledby="v-pills-settings-tab">
                                                <div className="img-hght">
                                                    <img className="new-facility-img1" alt="facility3"
                                                         src={getAssetPath("assets/imgs/new-home-facility2.png")}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="nav nav-pills" id="v-pills-tab" role="tablist">
                                            <div className="facility-bg-pill w-100">
                                                <div className="">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link facility-navlink-new active w-100"
                                                                id="v-pills-home-tab" data-bs-toggle="pill"
                                                                data-bs-target="#v-pills-home" type="button"
                                                                role="tab" aria-controls="v-pills-home"
                                                                aria-selected="true">
                                                            <div className="tab-facility-name">
                                                                <img className="facility-icon" alt="paperless"
                                                                     src={getAssetPath("assets/icons/paperless-icon.png")}></img>
                                                                <h3 className="facility-title home-facility-title font-bold">{facilitySection.desktopView.headinOneIntr}</h3>
                                                            </div>
                                                            <p className="new-facility-sbtxt new-home-facility-sbtxt">{facilitySection.desktopView.paraOne}</p>
                                                        </button>
                                                    </li>
                                                </div>
                                                <div className="">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link facility-navlink-new w-100"
                                                                id="v-pills-profile-tab" data-bs-toggle="pill"
                                                                data-bs-target="#v-pills-profile" type="button"
                                                                role="tab" aria-controls="v-pills-profile"
                                                                aria-selected="true">
                                                            <div className="tab-facility-name">
                                                                <img className="facility-icon" alt="app"
                                                                     src={getAssetPath("assets/icons/app-icon.png")}></img>
                                                                <h3 className="facility-title home-facility-title font-bold">{facilitySection.desktopView.headinTwoIntr}</h3>
                                                            </div>
                                                            <p className="new-facility-sbtxt new-home-facility-sbtxt">{facilitySection.desktopView.paraTwo}</p>
                                                        </button>
                                                    </li>
                                                </div>
                                                <div className="">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link facility-navlink-new w-100"
                                                                id="v-pills-settings-tab" data-bs-toggle="pill"
                                                                data-bs-target="#v-pills-settings" type="button"
                                                                role="tab" aria-controls="v-pills-settings"
                                                                aria-selected="true">
                                                            <div className="tab-facility-name">
                                                                <img className="facility-icon" alt="app"
                                                                     src={getAssetPath("assets/icons/whatsapp-icon.png")}></img>
                                                                <h3 className="facility-title home-facility-title font-bold">{facilitySection.desktopView.headinThreeIntr}</h3>
                                                            </div>
                                                            <p className="new-facility-sbtxt new-home-facility-sbtxt">{facilitySection.desktopView.paraThree}</p>
                                                        </button>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* mobile-view */}
                <div className="container">
                    <div className="row mobile-view">
                        <div className="col-md-12 facility-mobile-pd">
                            <div className="facility-card">
                                <div className="facility-main-card">
                                    <div className="facility-img">
                                        <img className="mobile-facilityimg" alt="facility-1"
                                             src={getAssetPath("assets/imgs/new-home-facility1.png")}></img>
                                    </div>
                                </div>
                                <div className="facility-content">
                                    <div className="facility-heading">
                                        <h4 className="font-semibold">{facilitySection.mobileView.headingOne}</h4>
                                    </div>
                                    <div className="facility-preview">
                                        <p className="font-regular">{facilitySection.mobileView.paraOne}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="facility-card">
                                <div className="facility-main-card">
                                    <div className="facility-img">
                                        <img className="mobile-facilityimg" alt="facility-2"
                                             src={getAssetPath("assets/imgs/new-home-facility3.png")}></img>
                                    </div>
                                </div>
                                <div className="facility-content">
                                    <div className="facility-heading">
                                        <h4 className="font-semibold">{facilitySection.mobileView.headingTwo}</h4>
                                    </div>
                                    <div className="facility-preview">
                                        <p className="font-regular">{facilitySection.mobileView.paraTwo}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="facility-card">
                                <div className="facility-main-card">
                                    <div className="facility-img">
                                        <img className="mobile-facilityimg" alt="facility-3"
                                             src={getAssetPath("assets/imgs/new-home-facility2.png")}></img>
                                    </div>
                                </div>
                                <div className="facility-content">
                                    <div className="facility-heading">
                                        <h4 className="font-semibold">{facilitySection.mobileView.headingThree}</h4>
                                    </div>
                                    <div className="facility-preview">
                                        <p className="font-regular">{facilitySection.mobileView.paraThree}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* leadship section */}
            <section className="company-overview new-cmp-ovr">
                {/* desktop-view */}
                {/* <div className="leadership-desktop-view ldr-bg">
                <div className="btns" style={{ textAlign: "right" }}>
                    <button className="prev-btn" onClick={previous}>
                        <img className="slick-arrow-prev" alt="slick-arrow" src={getAssetPath("assets/icons/Slick-arrow.png")}></img>
                    </button>
                    <button className="nxt-btn" onClick={next}>
                        <img className="slick-arrow-nxt" alt="slick-arrow" src={getAssetPath("assets/icons/Slick-arrow.png")}></img>
                    </button>
                </div>

                <Slider
                        ref={slider => {
                            sliderRef = slider;
                        }}{...leadershipslider}>
                    <div className="bapulad-over-wrp no-rad">
                        <div className="container leadership-details">
                            <div className="col-md-12 mb-3">
                                <h2 className="leadershipsec-title font bold">{leadershipSection.desktopView.slider3.title}</h2>
                            </div>
                            <div className="col-md-5">
                                <h2 className="quote font-bold">{leadershipSection.desktopView.slider3.headName}
                                </h2>
                                <p className="headname font-medium">{leadershipSection.desktopView.slider3.poisition}</p>
                                <p className="poisition font-medium"></p>
                                <p className="overview font-regular">{leadershipSection.desktopView.slider3.overview}</p>
                                <button className="btn btn-default sec-lm-btn hom-lm-btn" data-bs-toggle="modal" data-bs-target="#Modalbapulad">
                                    {leadershipSection.desktopView.slider3.btn}
                                    <img className="Arrow-white" alt="arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                </button>
                                <div className="social-links">
                                    <a href="https://www.youtube.com/channel/UC5KBHhwyVLLCT1nvrSEBWVw">
                                        <img className="social-icon" alt="yt" src={getAssetPath("assets/icons/leadership-yt.png")}></img>
                                    </a>
                                    <a href="https://www.facebook.com/people/%E0%A4%95%E0%A5%8D%E0%A4%B0%E0%A4%BE%E0%A4%82%E0%A4%A4%E0%A5%80%E0%A4%85%E0%A4%97%E0%A5%8D%E0%A4%B0%E0%A4%A3%E0%A5%80-%E0%A4%AA%E0%A4%B0%E0%A4%BF%E0%A4%B5%E0%A4%BE%E0%A4%B0/pfbid0oKWyV72a5h7YtNwwatpA7HK8hAvJS3u4JGvLRjnomshdw4Rzdvq7Teqjcof9TbZzl/">
                                        <img className="social-icon"  alt="fb" src={getAssetPath("assets/icons/fb.png")}></img>
                                    </a>
                                    <a href="mailto:md@krantisugar.com">
                                        <img className="social-icon"  alt="insta" src={getAssetPath("assets/icons/leadership-mail.png")}></img>
                                    </a>
                                    <a href="https://x.com/krantiagranipa1">
                                        <img className="social-icon"  alt="x" src={getAssetPath("assets/icons/x.png")}></img>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="leadership-over-wrp no-rad">
                        <div className="container leadership-details">
                            <div className="col-md-12 mb-3">
                                <h2 className="leadershipsec-title font bold">{leadershipSection.desktopView.slider1.title}</h2>
                            </div>
                            <div className="col-md-5">
                                <h2 className="quote font-bold">{leadershipSection.desktopView.slider1.headName}
                                </h2>
                                <p className="headname font-medium">{leadershipSection.desktopView.slider1.poisition}</p>
                                <p className="poisition font-medium"></p>
                                <p className="overview font-regular">{leadershipSection.desktopView.slider1.overview}</p>
                                <button className="btn btn-default sec-lm-btn hom-lm-btn" data-bs-toggle="modal" data-bs-target="#Modalarunlad">
                                    {leadershipSection.desktopView.slider1.btn}
                                    <img className="Arrow-white" alt="arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                </button>
                                <div className="social-links">
                                    <a href="https://www.linkedin.com/in/arunlad/?original_referer=https%3A%2F%2Fwww.krantisugar.com%2F">
                                        <img className="social-icon"  alt="linkedin" src={getAssetPath("assets/icons/linkedin.png")}></img>
                                    </a>
                                    <a href="https://www.facebook.com/ArunLadOfficial/reels/">
                                        <img className="social-icon"  alt="fb" src={getAssetPath("assets/icons/fb.png")}></img>
                                    </a>
                                    <a href="https://www.instagram.com/arun_lad_official/?igsh=MWxmNWhweG90MWtvYw%3D%3D">
                                        <img className="social-icon"  alt="insta" src={getAssetPath("assets/icons/insta.png")}></img>
                                    </a>
                                    <a href="https://x.com/NCPArunLad">
                                        <img className="social-icon"  alt="x" src={getAssetPath("assets/icons/x.png")}></img>
                                    </a>
                                    <a href="https://arunlad.com">
                                        <img className="social-icon"  alt="website" src={getAssetPath("assets/icons/leadership-web.png")}></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sharad-lad-wrp no-rad">
                        <div className="container leadership-details">
                            <div className="col-md-12 mb-3">
                                <h2 className="leadershipsec-title font bold">{leadershipSection.desktopView.slider2.title}</h2>
                            </div>
                            <div className="col-md-5">
                                <h2 className="quote font-bold">{leadershipSection.desktopView.slider2.headName}
                                </h2>
                                <p className="headname font-medium">{leadershipSection.desktopView.slider2.poisition}</p>
                                <p className="poisition font-medium"></p>
                                <p className="overview font-regular">{leadershipSection.desktopView.slider2.overview}</p>
                                <p className="overview font-regular">{leadershipSection.desktopView.slider2.overview2}</p>
                                <button className="btn btn-default sec-lm-btn hom-lm-btn" data-bs-toggle="modal" data-bs-target="#Modalsharadlad">
                                    {leadershipSection.desktopView.slider2.btn}
                                    <img className="Arrow-white" alt="arrow" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                </button>
                                <div className="social-links">
                                    <a href="">
                                        <img className="social-icon"  alt="linkedin" src={getAssetPath("assets/icons/linkedin.png")}></img>
                                    </a>
                                    <a href="https://www.facebook.com/SharadArunLad?mibextid=LQQJ4d&rdid=csUZslx5v6g3jge6&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fe2o2Z1L2JX5yL2gK%2F%3Fmibextid%3DLQQJ4d">
                                        <img className="social-icon"  alt="fb" src={getAssetPath("assets/icons/fb.png")}></img>
                                    </a>
                                    <a href="https://www.instagram.com/sharadarunlad/?igsh=MTdkNG45OTJyMmh4dg%3D%3D">
                                        <img className="social-icon"  alt="insta" src={getAssetPath("assets/icons/insta.png")}></img>
                                    </a>
                                    <a href="https://x.com/sharadlad81/status/1800092530736070718?t=dCCr7KlUKBo1yhtD0CsAQA&s=09">
                                        <img className="social-icon"  alt="x" src={getAssetPath("assets/icons/x.png")}></img>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div> */}

                {/* leadership section bg-fixed */}
                <div className="leadership-bapulad-bg">
                    <div className="col-md-6 info-bg">
                        <div className="container info-container">
                            <div className="col-md-6 mb-3">
                                <h2 className="leadershipsec-title new-leadershipsec-title font bold">{leadershipSection.desktopView.slider3.title}</h2>
                            </div>
                            <div className="col-md-9">
                                <h2 className="quote new-quote font-bold mb-0">{leadershipSection.desktopView.slider3.headName}
                                </h2>
                                <h2 className="quote new-quote font-bold">{leadershipSection.desktopView.slider3.headnamenxt}
                                </h2>
                                <p className="headname new-headname font-medium">{leadershipSection.desktopView.slider3.poisition}</p>
                                <p className="poisition new-position font-medium"></p>

                                <p className="overview new-overview font-regular">{leadershipSection.desktopView.slider3.overview}</p>
                                <button className="btn btn-default lead-lm-btn hom-lm-btn" data-bs-toggle="modal"
                                        data-bs-target="#Modalbapulad">
                                    {leadershipSection.desktopView.slider3.btn}
                                    <img className="Arrow-1" alt="arrow"
                                         src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                </button>
                                <div className="social-links">
                                    <a href="https://www.youtube.com/channel/UC5KBHhwyVLLCT1nvrSEBWVw">
                                        <img className="social-icon" alt="yt"
                                             src={getAssetPath("assets/icons/leadership-yt.png")}></img>
                                    </a>
                                    <a href="https://www.facebook.com/people/%E0%A4%95%E0%A5%8D%E0%A4%B0%E0%A4%BE%E0%A4%82%E0%A4%A4%E0%A5%80%E0%A4%85%E0%A4%97%E0%A5%8D%E0%A4%B0%E0%A4%A3%E0%A5%80-%E0%A4%AA%E0%A4%B0%E0%A4%BF%E0%A4%B5%E0%A4%BE%E0%A4%B0/pfbid0oKWyV72a5h7YtNwwatpA7HK8hAvJS3u4JGvLRjnomshdw4Rzdvq7Teqjcof9TbZzl/">
                                        <img className="social-icon" alt="fb"
                                             src={getAssetPath("assets/icons/fb.png")}></img>
                                    </a>
                                    <a href="mailto:md@krantisugar.com">
                                        <img className="social-icon" alt="insta"
                                             src={getAssetPath("assets/icons/leadership-mail.png")}></img>
                                    </a>
                                    <a href="https://x.com/krantiagranipa1">
                                        <img className="social-icon" alt="x"
                                             src={getAssetPath("assets/icons/x.png")}></img>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="leadership-arunlad-bg">
                    <div className="col-md-6 info-bg2">
                        <div className="container info-container">
                            <div className="col-md-6 mb-3">
                                <h2 className="leadershipsec-title new-leadershipsec-title font bold">{leadershipSection.desktopView.slider1.title}</h2>
                            </div>
                            <div className="col-md-9">
                                <h2 className="quote new-quote font-bold">{leadershipSection.desktopView.slider1.headName}
                                </h2>
                                <p className="headname new-headname font-medium">{leadershipSection.desktopView.slider1.poisition}</p>
                                <p className="poisition new-position font-medium"></p>

                                <p className="overview new-overview font-regular">{leadershipSection.desktopView.slider1.overview}</p>
                                <button className="btn btn-default lead-lm-btn hom-lm-btn" data-bs-toggle="modal"
                                        data-bs-target="#Modalarunlad">
                                    {leadershipSection.desktopView.slider1.btn}
                                    <img className="Arrow-1" alt="arrow"
                                         src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                </button>
                                <div className="social-links">
                                    <a href="https://www.linkedin.com/in/arunlad/?original_referer=https%3A%2F%2Fwww.krantisugar.com%2F">
                                        <img className="social-icon" alt="linkedin"
                                             src={getAssetPath("assets/icons/linkedin.png")}></img>
                                    </a>
                                    <a href="https://www.facebook.com/ArunLadOfficial/reels/">
                                        <img className="social-icon" alt="fb"
                                             src={getAssetPath("assets/icons/fb.png")}></img>
                                    </a>
                                    <a href="https://www.instagram.com/arun_lad_official/?igsh=MWxmNWhweG90MWtvYw%3D%3D">
                                        <img className="social-icon" alt="insta"
                                             src={getAssetPath("assets/icons/insta.png")}></img>
                                    </a>
                                    <a href="https://x.com/NCPArunLad">
                                        <img className="social-icon" alt="x"
                                             src={getAssetPath("assets/icons/x.png")}></img>
                                    </a>
                                    <a href="https://arunlad.com">
                                        <img className="social-icon" alt="website"
                                             src={getAssetPath("assets/icons/leadership-web.png")}></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="leadeship-sharadlad-bg">
                    <div className="col-md-6 info-bg">
                        <div className="container info-container">
                            <div className="col-md-6 mb-3">
                                <h2 className="leadershipsec-title new-leadershipsec-title font bold">{leadershipSection.desktopView.slider2.title}</h2>
                            </div>
                            <div className="col-md-9">
                                <h2 className="quote new-quote font-bold">{leadershipSection.desktopView.slider2.headName}
                                </h2>
                                <p className="headname new-headname font-medium">{leadershipSection.desktopView.slider2.poisition}</p>
                                <p className="poisition new-position font-medium"></p>

                                <p className="overview new-overview font-regular">{leadershipSection.desktopView.slider2.overview}</p>
                                <button className="btn btn-default lead-lm-btn hom-lm-btn" data-bs-toggle="modal"
                                        data-bs-target="#Modalsharadlad">
                                    {leadershipSection.desktopView.slider1.btn}
                                    <img className="Arrow-1" alt="arrow"
                                         src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                </button>
                                <div className="social-links">
                                    <a href="">
                                        <img className="social-icon" alt="linkedin"
                                             src={getAssetPath("assets/icons/linkedin.png")}></img>
                                    </a>
                                    <a href="https://www.facebook.com/SharadArunLad?mibextid=LQQJ4d&rdid=csUZslx5v6g3jge6&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fe2o2Z1L2JX5yL2gK%2F%3Fmibextid%3DLQQJ4d">
                                        <img className="social-icon" alt="fb"
                                             src={getAssetPath("assets/icons/fb.png")}></img>
                                    </a>
                                    <a href="https://www.instagram.com/sharadarunlad/?igsh=MTdkNG45OTJyMmh4dg%3D%3D">
                                        <img className="social-icon" alt="insta"
                                             src={getAssetPath("assets/icons/insta.png")}></img>
                                    </a>
                                    <a href="https://x.com/sharadlad81?s=11">
                                        <img className="social-icon" alt="x"
                                             src={getAssetPath("assets/icons/x.png")}></img>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* mobile-view */}
                <div className="leadership-mobile-view">


                    <div className="col-md-12 mb-5 mobile-pd">
                        <h2 className="mob-title font bold">{leadershipSection.mobileView.title}</h2>
                    </div>

                    <div className="container">
                        <div className="col-md-12">
                            <Slider
                                ref={slider => {
                                    mobRef = slider;
                                }}{...leadershipmobslider}>
                                <div className="facility-card">
                                    <div className="facility-main-card">
                                        <div className="facility-img">
                                            <img className="mobile-facilityimg" alt="facility-1"
                                                 src={getAssetPath("assets/imgs/bapu-lad.png")}></img>
                                        </div>
                                    </div>
                                    <div className="facility-content leadership-content">
                                        <div className="leadership-quote">
                                            <h4 className="font-semibold mb-0">{leadershipSection.mobileView.slider3.headName}
                                            </h4>
                                            <h4 className="font-semibold">{leadershipSection.mobileView.slider3.headnamenxt}
                                            </h4>
                                        </div>
                                        <div className="leadership-namepos">
                                            <p className="name font-regular">{leadershipSection.mobileView.slider3.poisition}</p>
                                            <p className="poistion font-regular"></p>
                                        </div>

                                        <div className="leadership-preview">
                                            <p className="font-regular">{leadershipSection.mobileView.slider3.overview}
                                            </p>
                                        </div>
                                        <button className="btn btn-default sec-lm-btn hom-lm-btn" data-bs-toggle="modal"
                                                data-bs-target="#Modalbapulad">
                                            {leadershipSection.mobileView.slider3.btn}
                                            <img className="Arrow-white" alt="arrow"
                                                 src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                        </button>

                                        <div className="social-links">
                                            <a href="https://www.youtube.com/channel/UC5KBHhwyVLLCT1nvrSEBWVw">
                                                <img className="social-icon" alt="yt"
                                                     src={getAssetPath("assets/icons/leadership-yt.png")}></img>
                                            </a>
                                            <a href="https://www.facebook.com/people/%E0%A4%95%E0%A5%8D%E0%A4%B0%E0%A4%BE%E0%A4%82%E0%A4%A4%E0%A5%80%E0%A4%85%E0%A4%97%E0%A5%8D%E0%A4%B0%E0%A4%A3%E0%A5%80-%E0%A4%AA%E0%A4%B0%E0%A4%BF%E0%A4%B5%E0%A4%BE%E0%A4%B0/pfbid0oKWyV72a5h7YtNwwatpA7HK8hAvJS3u4JGvLRjnomshdw4Rzdvq7Teqjcof9TbZzl/">
                                                <img className="social-icon" alt="fb"
                                                     src={getAssetPath("assets/icons/fb.png")}></img>
                                            </a>
                                            <a href="mailto:md@krantisugar.com">
                                                <img className="social-icon" alt="insta"
                                                     src={getAssetPath("assets/icons/leadership-mail.png")}></img>
                                            </a>
                                            <a href="https://x.com/krantiagranipa1">
                                                <img className="social-icon" alt="x"
                                                     src={getAssetPath("assets/icons/x.png")}></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="facility-card">
                                    <div className="facility-main-card">
                                        <div className="facility-img">
                                            <img className="mobile-facilityimg" alt="facility-1"
                                                 src={getAssetPath("assets/imgs/mobile-new-Arunlad(blur).png")}></img>
                                        </div>
                                    </div>
                                    <div className="facility-content leadership-content">
                                        <div className="leadership-quote">
                                            <h4 className="font-semibold">{leadershipSection.mobileView.slider1.headName}
                                            </h4>
                                        </div>
                                        <div className="leadership-namepos">
                                            <p className="name font-regular">{leadershipSection.mobileView.slider1.poisition}</p>
                                            <p className="poistion font-regular"></p>
                                        </div>

                                        <div className="leadership-preview">
                                            <p className="font-regular">{leadershipSection.mobileView.slider1.overview}
                                            </p>
                                        </div>
                                        <button className="btn btn-default sec-lm-btn hom-lm-btn" data-bs-toggle="modal"
                                                data-bs-target="#Modalarunlad">
                                            {leadershipSection.mobileView.slider3.btn}
                                            <img className="Arrow-white" alt="arrow"
                                                 src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                        </button>
                                        <div className="social-links">
                                            <a href="https://www.linkedin.com/in/arunlad/?original_referer=https%3A%2F%2Fwww.krantisugar.com%2F">
                                                <img className="social-icon" alt="linkedin"
                                                     src={getAssetPath("assets/icons/linkedin.png")}></img>
                                            </a>
                                            <a href="https://www.facebook.com/ArunLadOfficial/reels/">
                                                <img className="social-icon" alt="fb"
                                                     src={getAssetPath("assets/icons/fb.png")}></img>
                                            </a>
                                            <a href="https://www.instagram.com/arun_lad_official/?igsh=MWxmNWhweG90MWtvYw%3D%3D">
                                                <img className="social-icon" alt="insta"
                                                     src={getAssetPath("assets/icons/insta.png")}></img>
                                            </a>
                                            <a href="https://x.com/NCPArunLad">
                                                <img className="social-icon" alt="x"
                                                     src={getAssetPath("assets/icons/x.png")}></img>
                                            </a>
                                            <a href="https://arunlad.com">
                                                <img className="social-icon" alt="website"
                                                     src={getAssetPath("assets/icons/leadership-web.png")}></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="facility-card">
                                    <div className="facility-main-card">
                                        <div className="facility-img">
                                            <img className="mobile-facilityimg" alt="facility-1"
                                                 src={getAssetPath("assets/imgs/mobile-new-sharadlad.png")}></img>
                                        </div>
                                    </div>
                                    <div className="facility-content leadership-content">
                                        <div className="leadership-quote">
                                            <h4 className="font-semibold">{leadershipSection.mobileView.slider2.headName}
                                            </h4>
                                        </div>
                                        <div className="leadership-namepos">
                                            <p className="name font-regular">{leadershipSection.mobileView.slider2.poisition}</p>
                                            <p className="poistion font-regular"></p>
                                        </div>

                                        <div className="leadership-preview">
                                            <p className="font-regular">{leadershipSection.mobileView.slider2.overview}</p>
                                        </div>
                                        <button className="btn btn-default sec-lm-btn hom-lm-btn" data-bs-toggle="modal"
                                                data-bs-target="#Modalsharadlad">
                                            {leadershipSection.mobileView.slider3.btn}
                                            <img className="Arrow-white" alt="arrow"
                                                 src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                        </button>
                                        <div className="social-links">
                                            <a href="">
                                                <img className="social-icon" alt="linkedin"
                                                     src={getAssetPath("assets/icons/linkedin.png")}></img>
                                            </a>
                                            <a href="https://www.facebook.com/SharadArunLad?mibextid=LQQJ4d&rdid=csUZslx5v6g3jge6&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fe2o2Z1L2JX5yL2gK%2F%3Fmibextid%3DLQQJ4d">
                                                <img className="social-icon" alt="fb"
                                                     src={getAssetPath("assets/icons/fb.png")}></img>
                                            </a>
                                            <a href="https://www.instagram.com/sharadarunlad/?igsh=MTdkNG45OTJyMmh4dg%3D%3D">
                                                <img className="social-icon" alt="insta"
                                                     src={getAssetPath("assets/icons/insta.png")}></img>
                                            </a>
                                            <a href="https://x.com/sharadlad81?s=11">
                                                <img className="social-icon" alt="x"
                                                     src={getAssetPath("assets/icons/x.png")}></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>

                    <div className="btns" style={{textAlign: "center"}}>
                        <button className="prev-btn" onClick={mobprevious}>
                            <img className="slick-arrow-prev" alt="slick-arrow"
                                 src={getAssetPath("assets/icons/Slick-arrow.png")}></img>
                        </button>
                        <button className="nxt-btn" onClick={mobnext}>
                            <img className="slick-arrow-nxt" alt="slick-arrow"
                                 src={getAssetPath("assets/icons/Slick-arrow.png")}></img>
                        </button>
                    </div>
                </div>
            </section>
            {/* Tenders section */}
            <section className="investor-bg">
                <div className="container">
                    <div className="investor-head">
                        <h2 className="investor-title">{tenderSection.investorTitle}</h2>
                        <p className="newtender-sbtxt font-medium">{tenderSection.latestTenderTitle}</p>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mob-mr">
                            {/* <p className="tender-sbtitle font-medium">{tenderSection.tenderTitle}</p> */}
                            <div className="row  tender-mrg-btm">
                                <div className="col-md-3">
                                    <div className="notice-bg notice-hgt">
                                        <p className="notice-board font-medium">{tenderSection.noticeBoardText}</p>
                                        <div className="notice-lisitng">
                                            {noticeList && noticeList.map((data, index) => (
                                                <div className="notice-block nb-mrg" key={index}>
                                                    <div className="board-block new-borad-bullet"></div>
                                                    <div className="notice-namedate">
                                                        <button className="btn btn-btn-link"
                                                                onClick={() => {
                                                                    window.open(data.attachment, '_blank');
                                                                }}>
                                                            {data.attachment.substring(data.attachment.lastIndexOf('/') + 1)}
                                                        </button>
                                                        {/*<button className="btn btn-btn-link"*/}
                                                        {/*        onClick={() => handleDownload(data)}>{data.attachment.substring(data.attachment.lastIndexOf('/') + 1)}</button>*/}
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="tender-mobile-gap">
                                </div>
                                {latestTender.map((tender, index) => (
                                    <div className="col-md-3" key={index}
                                         onClick={() => handleTenderAttachment(tender)}>
                                        <div className="new-tender-card">
                                            <div className="tender-content">
                                                <div className="datedesc pd-datedesc">
                                                    <p className="tendartitle font-semibold">{tender.title}</p>
                                                    <p className="tendardate font-medium">{tender.start_date}</p>
                                                </div>
                                                <div className="tender-desc">
                                                    <p className="new-tendar-desc font-regular">
                                                        The tender No. 1061, Tender for CPU (200m3/hr) & Tender No. 1062
                                                        Tender For Spray
                                                    </p>
                                                </div>
                                                <div className="tender-divider"></div>
                                                <button className="btn btn-default btn-round dwnlod-btn w-100"
                                                        onClick={() => handleTenderAttachment(tender)}>{tenderSection.downloadText}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                                <div className="col-md-3">
                                </div>
                            </div>
                            <button className="btn btn-default btn-round learn-more-2"
                                    onClick={() => handleButtonClick()}>{tenderSection.buttonText}
                                <img className="Arrow-1" src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                            </button>
                        </div>
                        {/* <div className="offset-md-1  col-md-3">
                        <div className="notice-bg">
                            <p className="notice-board font-medium">{tenderSection.noticeBoardText}</p>
                            <div className="notice-lisitng">
                            {noticeList && noticeList.map((data, index) => (
                            <div className="notice-block" key={index}>
                                <div className="board-block">
                                    <img className="board-icon" alt="board-doc" src={getAssetPath("assets/icons/notice-icon.png")}></img>
                                </div>
                                <div className="notice-namedate">
                                <button className="btn btn-btn-link" onClick={() => handleDownload(data)}>{data.attachment.substring(data.attachment.lastIndexOf('/') + 1)}</button>
                                </div>
                            </div>
                            ))
                            }
                            </div>
                        </div>
                    </div> */}
                    </div>
                </div>
            </section>


            {/* newsroom-section */}
            <section className="farmer-corner-new">
                <div className="farmer-corner-wrp-new">
                    <div className="container cmpn-details">
                        <div className="col-md-6">
                            <h2 className="farmerc-title font-bold">{newsRoom.investorTitleOne}</h2>
                            <h2 className="farmercorner-title mb-3"></h2>
                            <h4 className="fc-sbtitle font-medium">
                                {newsRoom.fcTitle}
                            </h4>
                        </div>
                        <div className="col-md-5">
                            <p className="farmerc-subtxt font-regular">
                                {companyOverview.para}
                            </p>
                        </div>
                        <div className="col-md-2">
                            <a href="FarmerInfo">
                                <button className="btn btn-default btn-round learn-more-2 mb-5">{newsRoom.learnMore}
                                    <img className="Arrow-1" alt="arrow"
                                         src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/*gallery section */}
            {
                galleryImageList &&  (galleryImageList.length > 0) && (
                    <section className="ngfc-bg">
                        <div className="container">
                            <div className="newsroom">
                                <div className="newsmrg">
                                    <div className="col-md-12 mb-1">
                                        <h2 className="investor-title mb-3">{newsRoom.investorTitleTwo}</h2>
                                        <h4 className="gallery-sbtitle font-medium">
                                            {newsRoom.kgTitle}
                                        </h4>
                                    </div>
                                    <div className="row">
                                        {galleryImageList.slice(0,6).map((item, index) => (
                                            <div className="col-md-4 gallery-pd">
                                                <div className=" gallery-pd no-pd mrg-btm">
                                                    <div className="cane-facility-img" data-bs-toggle="modal"
                                                         data-bs-target="#Modalhomeimg" key={item.id}
                                                         onClick={() => openModal(item.file_path)}>
                                                        <img className="img-fluid gallery-img" src={item.file_path}
                                                             alt={item.title}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-md-12 d-flex justify-content-center">
                                        <a href="KrantiGallery">
                                            <button
                                                className="btn btn-default btn-round learn-more-2 mt-5 mb-5">{newsRoom.learnMore}
                                                <img className="Arrow-1" alt="arrow"
                                                     src={getAssetPath("assets/icons/Arrow-1.png")}></img>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }


            {/* leadership section modal */}
            <div
                className="modal fade"
                id="Modalbapulad"
                tabIndex="-1"
                aria-labelledby="Modalbapulad"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <a href="/" className="close" data-bs-dismiss="modal">
                                <img
                                    className="img-fluid"
                                    alt="close-modal"
                                    src={getAssetPath("assets/icons/close-modal.png")}
                                ></img>
                            </a>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="leadership-modal-img">
                                        <img
                                            className="img-fluid w-100"
                                            alt="sharad-lad"
                                            src={getAssetPath("assets/imgs/ourteam-img/bapu-lad.png")}></img>
                                    </div>
                                </div>
                                <div className="col-md-8 scroll-content">
                                    <h2 className="leader-name-modal font-bold">
                                        {leadershipSection.modal1.name}
                                    </h2>
                                    <h5 className="leader-pos-modal font-regular">{leadershipSection.modal1.position}</h5>

                                    <p>
                                        {leadershipSection.modal1.para1}
                                    </p>
                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading1}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para2}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading2}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para3}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading3}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para4}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading4}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para5}
                                    </p>
                                    <p>
                                        {leadershipSection.modal1.para6}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading5}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para7}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading6}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para8}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading7}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para9}
                                    </p>
                                    <p>
                                        {leadershipSection.modal1.para10}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading8}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para11}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading9}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para12}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading10}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para13}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading11}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para14}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading12}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para15}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading13}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para16}
                                    </p>
                                    <p>
                                        {leadershipSection.modal1.para19}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading14}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para17}
                                    </p>
                                    {/* -------------------------------- */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal1.heading15}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal1.para18}
                                    </p>
                                    {/* -------------------------------- */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade"
                id="Modalarunlad"
                tabIndex="-1"
                aria-labelledby="Modalarunlad"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <a href="/" className="close" data-bs-dismiss="modal">
                                <img
                                    className="img-fluid"
                                    alt="close-modal"
                                    src={getAssetPath("assets/icons/close-modal.png")}
                                ></img>
                            </a>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="leadership-modal-img">
                                        <img
                                            className="img-fluid w-100"
                                            alt="sharad-lad"
                                            src={getAssetPath("assets/imgs/ourteam-img/arun-lad.png")}></img>
                                    </div>
                                </div>
                                <div className="col-md-8 scroll-content">
                                    <h2 className="leader-name-modal font-bold">
                                        {leadershipSection.modal2.name}
                                    </h2>
                                    <h5 className="leader-pos-modal font-regular">{leadershipSection.modal2.position}</h5>

                                    <p>
                                        {leadershipSection.modal2.para1}
                                    </p>

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal2.heading1}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal2.para2}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal2.heading2}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal2.para3}
                                    </p>
                                    <p>
                                        {leadershipSection.modal2.para4}
                                    </p>
                                    <p>
                                        {leadershipSection.modal2.para5}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal2.heading3}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal2.para6}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal2.heading4}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal2.para7}
                                    </p>
                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal2.headingfour}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal2.para8}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal2.heading5}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal2.para9}
                                    </p>
                                    <p>
                                        {leadershipSection.modal2.para10}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal2.heading6}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal2.para11}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal2.heading7}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal2.para13}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal2.heading8}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal2.para14}
                                    </p>
                                    {/* {/* --------------------------------} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade"
                id="Modalsharadlad"
                tabIndex="-1"
                aria-labelledby="Modalsharadlad"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <a href="/" className="close" data-bs-dismiss="modal">
                                <img
                                    className="img-fluid"
                                    alt="close-modal"
                                    src={getAssetPath("assets/icons/close-modal.png")}
                                ></img>
                            </a>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="leadership-modal-img">
                                        <img
                                            className="img-fluid w-100"
                                            alt="sharad-lad"
                                            src={getAssetPath("assets/imgs/ourteam-img/leader-sharadlad.png")}></img>
                                    </div>
                                </div>
                                <div className="col-md-8 scroll-content">
                                    <h2 className="leader-name-modal font-bold">
                                        {leadershipSection.modal3.name}
                                    </h2>
                                    <h5 className="leader-pos-modal font-regular">{leadershipSection.modal3.position}</h5>

                                    <p>
                                        {leadershipSection.modal3.para1}
                                    </p>

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal3.heading1}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal3.para2}
                                    </p>
                                    <p>
                                        {leadershipSection.modal3.para11}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal3.heading2}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal3.para3}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal3.heading3}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal3.para4}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal3.heading4}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal3.para5}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal3.heading5}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal3.para6}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal3.heading6}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal3.para7}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal3.heading7}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal3.para8}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal3.heading8}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal3.para9}
                                    </p>
                                    {/* {/* --------------------------------} */}

                                    <div className="bt-title font-semibold">
                                        <p>{leadershipSection.modal3.heading9}</p>
                                    </div>
                                    <p>
                                        {leadershipSection.modal3.para10}
                                    </p>
                                    {/* {/* --------------------------------} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* gallery-imgs-modal */}
            <div className="modal fade" id="Modalhomeimg" tabIndex="-1" aria-labelledby="Modalhomeimg"
                 aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <a href="/" className="close" data-bs-dismiss="modal">
                                <img className="img-fluid" alt="close-modal"
                                     src={getAssetPath("assets/icons/close-modal.png")}></img>
                            </a>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="leadership-modal-img">
                                        <img className="img-fluid ks-gallery-img w-100" alt="gallery-img"
                                             src={selectedImage}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
