import React, {useEffect, useState} from "react";
import "./FcCaneReq.css";
import {getAssetPath} from "../../../services/helpers";
import {useTranslation, Trans} from "react-i18next";
import {getLocalStorageData, storeData} from "../../../services/localStorageService";
import {postApi, postApiWithAuth} from "../../../services/api/api";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const FcCaneReq = () => {
    const {t} = useTranslation();
    const {bannerInfo, requestForm} = t("fccanereq");
    const navigate = useNavigate();

    // Define initial form data state
    const initialFormData = {
        mobile: '',
        code: '',
        survey: '',
        file: null,
        uploadFileBase64: ""
        // Add other form fields as needed
    };

    const [formData, setFormData] = useState(initialFormData);

    const [ip, setIp] = useState(null);

    const [errors, setErrors] = useState({});

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setIp(response.data.ip);
            } catch (error) {
                console.error("Error fetching IP address:", error);
            }
        };

        fetchIp().then(r => console.log(''));
    }, []);


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        let error = "";

        // Validation based on input name
        if (name === "mobile") {
            // 10-digit number only
            if (!/^\d{0,10}$/.test(value)) {
                error = "Mobile number must be 10 digits.";
            }
        } else if (name === "code") {
            // Max 8-digit number only
            if (!/^\d{0,8}$/.test(value)) {
                error = "Farmer code must be up to 8 digits.";
            }
        } else if (name === "survey") {
            // Max 20 alphanumeric characters
            if (!/^[a-zA-Z0-9]{0,20}$/.test(value)) {
                error = "Survey number must be up to 20 alphanumeric characters.";
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let error = "";
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({
                    ...formData,
                    file: file,
                    uploadFileBase64: reader.result
                });
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    file: "",
                }));
            };
            reader.readAsDataURL(file);
        } else {
            error = "File upload is required.";
            setErrors((prevErrors) => ({
                ...prevErrors,
                file: error,
            }));
        }
    };

    const validateField = (name, value) => {
        let error = "";

        if (name === "mobile") {
            if (!/^\d{10}$/.test(value)) {
                error = "Mobile number must be exactly 10 digits.";
            }
        } else if (name === "code") {
            if (!/^\d{1,8}$/.test(value)) {
                error = "Farmer code must be up to 6 digits.";
            }
        } else if (name === "survey") {
            if (!/^[a-zA-Z0-9]{1,20}$/.test(value)) {
                error = "Survey number must be up to 3 digit numeric characters.";
            }
        } else if (name === "file" && !value) {
            error = "File upload is required.";
        }

        return error;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate all fields on submit
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            const error = validateField(key, formData[key]);
            if (error) newErrors[key] = error;
        });

        setErrors(newErrors);

        // If there are no errors, proceed with form submission
        if (Object.keys(newErrors).length === 0) {

            const actualData = {
                'farm_image': formData.uploadFileBase64,
                'farmer_code': formData.code,
                'survey_no': formData.survey,
                'farmer':formData.code,
                'mobile_no':formData.mobile,
                'ip':ip,
                'request_platform':'W',
            };

            try {
                await postApiWithAuth('caneregistration-request/', actualData);
                toast.success("Form submitted successfully!");
                setFormData(initialFormData);
                handleShow();
            } catch (error) {
                // Check if the error is a 400 error
                if (error.response && error.response.status === 400) {
                    const errorMessage = error.response.data.ip ? error.response.data.ip[0] : "Bad Request: Please check your input.";
                    toast.error(errorMessage);  // Show the server error in toast
                } else if (error.code === 'ERR_NETWORK') {
                    toast.error("Network error: Please try again later.");
                } else {
                    toast.error("An unexpected error occurred. Please try again.");
                }

                console.error("Error posting data:", error); // Log error details to the console
            }
        }
    };

    const handleToRedirectLocation = (path) => {
        navigate(path);
    };

    return (
        <>
            <div className="Tenders-headbanner">
                <div className="Farmer-banner col-md-12">
                    <div className="container">
                        <h1 className="headingtenders font-bold">{bannerInfo.heading}</h1>
                        <div className="breadcrum">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb bg-container">
                                    <li className="breadcrumb-item brdm-itm">
                                        <button
                                            onClick={() => handleToRedirectLocation('/home')}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: 'inherit', // Use inherited color from parent
                                                textDecoration: 'underline', // Makes it look like a link
                                                cursor: 'pointer',
                                            }}
                                            tabIndex={0} // Make it focusable for keyboard users
                                        >
                                            {bannerInfo.link}
                                        </button>
                                    </li>
                                    <li
                                        className="breadcrumb-item brdm-itm"
                                        aria-current="page">
                                        <button
                                            onClick={() => handleToRedirectLocation('/fc-dashboard')}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: 'inherit', // Use inherited color from parent
                                                textDecoration: 'underline', // Makes it look like a link
                                                cursor: 'pointer',
                                            }}
                                            tabIndex={0} // Make it focusable for keyboard users
                                        >{bannerInfo.text}
                                        </button>
                                    </li>
                                    <li
                                        className="breadcrumb-item brdm-itm active"
                                        aria-current="page">
                                        {bannerInfo.text2}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <section className="tender-table">
                <div className="tendermain-table">
                    <div className="col-md-12">
                        <div className="famrer-logout mb-3">
                            <a onClick={() => handleToRedirectLocation('/fc-dashboard')}>
                                <button
                                    className="btn btn-default btn-round tender-Register btn-back"
                                >
                                    <img
                                        className="back"
                                        alt="back"
                                        src={getAssetPath("assets/icons/back-arrow.png")}
                                    ></img>
                                    {requestForm.back}
                                </button>
                            </a>
                        </div>
                        <div className="login-search mb-30">
                            <div className="farmer-name">
                                <div className="username">
                                    <h2 className="font-bold">
                                        {requestForm.tablename}
                                    </h2>
                                </div>
                                <p className="dash-text regular-font">
                                    {requestForm.para}
                                </p>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className="col-md-6 brdr-rght-btm">
                                <div className="mobile-input  mb-4">
                                    <div className="input-grp mb-0">
                                        <img
                                            className="user-icon"
                                            src={getAssetPath("assets/icons/phone-icon.png")}
                                            alt="phone"
                                        />
                                        <input
                                            placeholder={requestForm.number}
                                            id="mobile"
                                            name="mobile"
                                            className="form-control rounded-0 font-regular"
                                            type="number"
                                            value={formData.mobile}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.mobile && <small className="text-danger">{errors.mobile}</small>}
                                </div>
                                <div className="code-input mb-4">
                                    <div className="input-grp mb-0">
                                        <img
                                            className="user-icon"
                                            src={getAssetPath("assets/icons/user-icon.png")}
                                            alt="user"
                                        />
                                        <input
                                            placeholder={requestForm.code}
                                            id="code"
                                            name="code"
                                            className="form-control rounded-0 font-regular"
                                            type="text"
                                            value={formData.code}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.code && <small className="text-danger">{errors.code}</small>}
                                </div>
                                <div className="survey-input">
                                    <div className="input-grp mb-0">
                                        <img
                                            className="user-icon"
                                            src={getAssetPath("assets/icons/number.png")}
                                            alt="user"
                                        />
                                        <input
                                            placeholder={requestForm.survey}
                                            id="survey"
                                            name="survey"
                                            className="form-control rounded-0 font-regular"
                                            type="text"
                                            value={formData.survey}
                                            onChange={handleInputChange}
                                        />

                                    </div>
                                    {errors.survey && <small className="text-danger">{errors.survey}</small>}
                                </div>
                            </div>
                            <div className="col-md-6 pd-lft">
                                <div
                                    className="upload-farm-doc"
                                    onClick={() => document.querySelector(".upload-file").click()}
                                >
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className="upload-file"
                                        id="upload-file"
                                        hidden
                                        onChange={handleFileChange}
                                    />
                                    <img
                                        className="file"
                                        alt="upload-file"
                                        src={getAssetPath("assets/icons/upload-file.png")}
                                    />
                                    <p className="font-regular mb-0">{requestForm.upload}</p>
                                </div>
                                {errors.file && <small className="text-danger">{errors.file}</small>}
                            </div>
                        </div>
                        <div className="cancel-submit-req">
                            <button type="button" className="btn btn-default btn-round req-cancel">
                                {requestForm.cancel}
                            </button>
                            <button
                                type="submit"
                                className="btn btn-default btn-round req-submit"
                            >
                                {requestForm.submit}
                            </button>
                        </div>
                        {formData.uploadFileBase64 && (
                            <div className="preview">
                                <h5>Image Preview:</h5>
                                <img src={formData.uploadFileBase64} alt="Preview" style={{maxWidth: '100%'}}/>
                            </div>
                        )}
                    </form>
                </div>
            </section>

            {/* submit success */}
            {showModal && (
                <div
                    className="modal cstm-overlay fade show"
                    style={{ display: "block" }}
                    tabIndex="-1"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mr-auto">
                                        <div className="confirm-bg">
                                            <img
                                                className="sucess"
                                                alt="success"
                                                src={getAssetPath("assets/icons/Sucess-icon.png")}
                                            />
                                            <h2 className="sucess-title font-bold mb-0">
                                                {requestForm.success.heading}
                                            </h2>
                                            <p className="sucess-sbtxt font-regular">
                                                {requestForm.success.msg}
                                            </p>
                                            <div className="confirm-btns">
                                                <button
                                                    className="btn btn-default btn-round tender-submit"
                                                    onClick={handleClose}
                                                >
                                                    {requestForm.success.okay}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="modal-backdrop fade show"></div>*/}
                </div>
            )}
        </>
    )
}

export default FcCaneReq
