import React, {useEffect, useState} from "react";
import "./FcCaneWeight.css";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation } from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {getLocalStorageData} from "../../../services/localStorageService";
import {getApiDiffHeaders} from "../../../services/api/api";

const FcWeightDetail = () => {
    const { t } = useTranslation();
    const { bannerInfo, registerTable} = t("fcweightdetail");
    const navigate = useNavigate();
    const farmerCode = getLocalStorageData('farmerCode') ?? null;
    const [totalArrayList, setTotalArrayList] = useState([]);
    const location = useLocation();
    const { param, seasonDate } = location.state || {};  // default to empty object if state is undefined

    useEffect(() => {
            getTonnageDetails().catch((error) => console.error("Error in getTonnageDetails:", error));
    }, []);

    const handleToRedirectLocation = (path) => {
        navigate(path);
    };

    const getTonnageDetails = async () => {
        // Prepare the data to send in the API request
        const requestData = {
            Registration_Season: seasonDate,
            Farmer_Code: farmerCode,
            Harvesting_Number: param.Harvesting_Number,
        };

        try {
            // Fetch the tonnage details from the API
            const tonnageResponse = await getApiDiffHeaders('tonnage-detail/', requestData);
            console.log('Tonnage Response:', tonnageResponse);

            // Check if the response contains total data
            if (tonnageResponse) {
                const totalArray = Object.values(tonnageResponse.total || {});
                setTotalArrayList(totalArray);
            }
        } catch (error) {
            console.error('Failed to fetch tonnage details:', error);
        }
    };


    return (
        <>
        <div className="Tenders-headbanner">
            <div className="Farmer-banner col-md-12">
                <div className="container">
                    <h1 className="headingtenders font-bold">{bannerInfo.heading}</h1>
                    <div className="breadcrum">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-container">
                                <li className="breadcrumb-item brdm-itm">
                                    <button
                                        onClick={() => handleToRedirectLocation('/home')}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'inherit', // Use inherited color from parent
                                            textDecoration: 'underline', // Makes it look like a link
                                            cursor: 'pointer',
                                        }}
                                        tabIndex={0} // Make it focusable for keyboard users
                                    >
                                        {bannerInfo.link}
                                    </button>
                                </li>
                                <li
                                    className="breadcrumb-item brdm-itm"
                                    aria-current="page">
                                    <button
                                        onClick={() => handleToRedirectLocation('/fc-dashboard')}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'inherit', // Use inherited color from parent
                                            textDecoration: 'underline', // Makes it look like a link
                                            cursor: 'pointer',
                                        }}
                                        tabIndex={0} // Make it focusable for keyboard users
                                    >
                                        {bannerInfo.text}
                                    </button>
                                </li>
                                <li
                                    className="breadcrumb-item brdm-itm active"
                                    aria-current="page">
                                    {bannerInfo.text2}
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

            <section className="tender-table">
                <div className="tendermain-table">
                    <div className="col-md-12">
                        <div className="famrer-logout mb-3">
                            <button
                                onClick={() => {
                                    handleToRedirectLocation('/fc-cane-weight')
                                }}
                                className="btn btn-default btn-round tender-Register btn-back"
                            >
                            <img
                                className="back"
                                alt="back"
                                src={getAssetPath("assets/icons/back-arrow.png")}
                            ></img>
                            {registerTable.back}
                        </button>
                    </div>
                    <div className="login-search mb-30">
                        <div className="farmer-name">
                            <div className="username">
                                <h2 className="font-bold">
                                    {registerTable.tonnage}
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive mb-3">
                        <table className="table table-striped table-hover ">
                            <thead className="thead-dark th-height">
                                <tr>
                                    <th scope="col">{registerTable.tableHead.text1}</th>
                                    <th scope="col">{registerTable.tableHead.text2}</th>
                                    <th scope="col">{registerTable.tableHead.text3}</th>
                                    <th scope="col">{registerTable.tableHead.text4}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {Array.isArray(totalArrayList) && totalArrayList.length > 0 ? (
                                totalArrayList.map((singleRec,index) => (
                                        <tr className="tr-height" key={totalArrayList.length-index}>
                                            <td>{singleRec.Weighment_Date}</td>
                                            <td>{singleRec.Weighment_Net_Weight}</td>
                                            <td>{singleRec.Weighment_Slip_Number}</td>
                                            <td>{singleRec.Weighment_Vehicle_No}</td>
                                        </tr>
                                    ))
                            ) : (
                                <tr>
                                    <td colSpan="8">
                                        <p className="result-text text-center regular-font">No Sugarcane Weights Found</p>
                                    </td>
                                </tr>

                            )}
                            </tbody>
                        </table>
                    </div>
                    {
                        Array.isArray(totalArrayList) && totalArrayList.length > 0 ?
                            <p className="result-text text-center regular-font">{registerTable.results.text1} {totalArrayList.length} {registerTable.results.text2}</p>
                            :null
                    }
                </div>
            </div>
        </section>
        </>
    )
}


export default FcWeightDetail
